import { StackPanel, Text, TextField } from 'components';

import { SPACING } from 'Utilities/Layout';
import { TextBlock } from '@bxgrandcentral/controls';
import { useMemo } from 'react';

type Props = {
    isVisible?: boolean;
    countries?: string[];
    isDealChanged?: boolean;
};

export default function DealJurisdictionCountry({ isVisible, countries = [], isDealChanged }: Props) {
    const countryList = useMemo(() => countries?.join(', '), [countries]);

    if (!isVisible) {
        return null;
    }

    return (
        <StackPanel>
            <TextBlock styleName='fieldLabelStyle' text='Deal Jurisdiction Country' />
            <TextField
                value={countryList}
                isEnabled={false}
                maxHeight={300}
                variant='default'
                textWrapping='wrap'
                padding={`${SPACING.XS}px 0`}
            />
            {isDealChanged && (
                <Text variant='label-italic' padding={`${SPACING.XXS}px 0 0 0`}>
                    (Note: The associated deal has changed. The list of jurisdiction country will be updated after
                    approval.)
                </Text>
            )}
        </StackPanel>
    );
}
