import styled from 'styled-components';
import { SPACING } from 'Utilities/Layout';

type StyleProps = Partial<{
    padding: number;
}>;

export const Component = styled.div`
    padding: ${SPACING.SM}px 0 ${SPACING.MD}px ${SPACING.MD}px;
`;

export const Content = styled.div<StyleProps>`
    padding: ${({ padding = 0 }) => `${SPACING.XXS}px ${padding}px`};
    padding-top: 0;
`;

export const Header = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 30px 1fr 80px;
    grid-gap: ${SPACING.SM}px;
    margin-bottom: ${SPACING.XXS}px;
`;

export const SubItem = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 80px;
    grid-gap: ${SPACING.SM}px;
    padding-right: ${SPACING.XL}px;
`;
