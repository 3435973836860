import {
    CREATE_ENTITY_STATE,
    CreateLegalEntityWorkflow,
    DISSOLUTION_FINANCE_STATE,
    DISSOLUTION_LEGAL_STATE,
    DISSOLUTION_LITIGATION_STATE,
    DISSOLUTION_STATE,
    DISSOLUTION_STATUS,
    DISSOLUTION_TAX_STATE,
    DISSOLUTION_TREASURY_STATE,
} from 'models/LegalEntityRequest/Workflow';
import { Capabilities, RequestStepPanel, RequestStepsView, UserScopes } from '../RequestStepsView';
import { NewStructureType, isUnitedStates } from 'models';
import { isDissolutionStepEnabled, isInformationStepEnabled } from '../LegalEntityCreationViewHelpers';
import { isNil, sortBy } from 'lodash';
import {
    setIsCTAReview,
    setIsEntityDissolved,
    setLegalEntityCreationSelectedPanel,
    setSideBarLength,
    useCreationViewContext,
} from '../context/creation-view-context';
import { useEffect, useMemo } from 'react';

import useCheckPanelIsViewable from './use-check-panel-is-viewable';
import useHasCapability from './use-has-capability';
import { useHistory } from 'react-router-dom';
import useIsSupportedEntity from './use-is-supported-entity';
import useOnlyCtaReviewer from 'hooks/use-only-cta-reviewer';
import useReloadPage from './use-reload-page';
import useSidebarCustomIcon from './use-sidebar-custom-icon';

export default function useSideBarItems({ isStandalone = false }: { isStandalone?: boolean }) {
    const {
        state: {
            legalEntityOId,
            userScopes,
            sections,
            noPendingSections,
            entityCreationWorkItem,
            foreignRegistrationWorkItems,
            foreignRegistrationUpdateWorkItem,
            financeInformationWorkItem,
            taxClassificationWorkItem,
            legalEntityUpdateWorkItem,
            ownershipWorkItem,
            legalEntityDissolutionParentWorkItem,
            legalEntityDissolutionFinanceWorkItem,
            legalEntityDissolutionTreasuryWorkItem,
            legalEntityDissolutionTaxWorkItem,
            legalEntityDissolutionLitigationWorkItem,
            legalEntityDissolutionLegalWorkItem,
        },
        dispatch,
    } = useCreationViewContext();

    const historyApi = useHistory();
    const hasCapability = useHasCapability();
    const reloadPage = useReloadPage();
    const getSidebarCustomIcon = useSidebarCustomIcon();
    const { checkPanelIsViewable, hasViewAllRole } = useCheckPanelIsViewable();
    const { isOnlyCtaReviewer: isCtaReview } = useOnlyCtaReviewer(
        sections.CorporateTransparency.reviewDate?.toString(),
        entityCreationWorkItem
    );
    const isSupportedEntity = useIsSupportedEntity();

    const generatePanel = (step: RequestStepsView, section: string, hasActions: boolean, customIcon?: string) => {
        return { requestStepView: step, title: step, section, hasActions, customIcon };
    };

    const showSubmitButton = useMemo(() => {
        return isNil(legalEntityOId) || sections.target?.madeInNewSystems;
    }, [legalEntityOId, sections.target]);

    const isEntityDissolved = useMemo(() => {
        const entityWasDissolvedInRC =
            legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.COMPLETE;

        const entityWasDissolvedInMDM =
            !entityCreationWorkItem &&
            sections.LegalEntityInformation.RegistrationData?.filter(
                ({ isDomestic, dissolutionDate }) => isDomestic && dissolutionDate
            ).length === 1;

        return entityWasDissolvedInRC || entityWasDissolvedInMDM;
    }, [
        legalEntityDissolutionParentWorkItem,
        entityCreationWorkItem,
        sections.LegalEntityInformation.RegistrationData,
    ]);

    const ctaAutoSubmit = useMemo(() => {
        const registrations = [
            ...(noPendingSections.CorporateTransparency.domesticRegistration
                ? noPendingSections.CorporateTransparency.domesticRegistration
                : []),
            ...(noPendingSections.CorporateTransparency.foreignRegistrations
                ? noPendingSections.CorporateTransparency.foreignRegistrations
                : []),
        ];

        const usRegistrations = registrations.some(({ domesticCountry }) => isUnitedStates(domesticCountry));
        const reitStructureType = noPendingSections.CorporateTransparency.newStructureTypeId === NewStructureType.REIT;
        return !usRegistrations || reitStructureType;
    }, [
        noPendingSections.CorporateTransparency.foreignRegistrations,
        noPendingSections.CorporateTransparency.domesticRegistration,
    ]);

    useEffect(() => {
        setIsEntityDissolved(dispatch, isEntityDissolved);
    }, [dispatch, isEntityDissolved]);

    useEffect(() => {
        setIsCTAReview(dispatch, isCtaReview);
    }, [dispatch, isCtaReview]);

    const getCreationStepPanels = (workitem?: CreateLegalEntityWorkflow | undefined) => {
        const entityCreationState = workitem?.workItemState;
        if (isInformationStepEnabled(workitem, legalEntityOId)) {
            const creationStepPanels: RequestStepPanel[] = [
                generatePanel(
                    RequestStepsView.Legal_Entity_Information,
                    'LegalInformation',
                    !isEntityDissolved &&
                        legalEntityUpdateWorkItem?.workItemStatus !== 'Canceled' &&
                        ((hasCapability(Capabilities.approveLegalInformation) &&
                            legalEntityUpdateWorkItem?.workItemState === 'Legal Entity Update Approval') ||
                            (hasCapability(Capabilities.createLegalInformation) &&
                                legalEntityUpdateWorkItem?.workItemStatus === 'InProcess' &&
                                legalEntityUpdateWorkItem.workItemState !== DISSOLUTION_STATE.SAVE_DATA))
                ),
            ];

            return creationStepPanels;
        }

        const creationStepPanels: RequestStepPanel[] = [
            generatePanel(
                RequestStepsView.Request,
                'Details',
                (showSubmitButton && isNil(entityCreationState)) ||
                    (entityCreationState === CREATE_ENTITY_STATE.REVIEW &&
                        hasCapability(Capabilities.approveRequest)) ||
                    (entityCreationState === CREATE_ENTITY_STATE.UPDATE_REQUEST &&
                        hasCapability(Capabilities.createRequest))
            ),
            generatePanel(
                RequestStepsView.Document_Preparation,
                'DocumentPreparation',
                (hasCapability(Capabilities.createDocumentPreparation) &&
                    (entityCreationState === CREATE_ENTITY_STATE.DOCUMENT_PREPARATION ||
                        entityCreationState === CREATE_ENTITY_STATE.UPDATE_DOCUMENT_PREPARATION)) ||
                    (hasCapability(Capabilities.approveDocumentPreparation) &&
                        entityCreationState === CREATE_ENTITY_STATE.DOCUMENT_REVIEW)
            ),
            generatePanel(
                RequestStepsView.Domestic_Registration,
                'DomesticRegistration',
                hasCapability(Capabilities.createDomesticRegistration) &&
                    (entityCreationState === CREATE_ENTITY_STATE.REGISTRATION ||
                        entityCreationState === CREATE_ENTITY_STATE.UPDATE_DOMESTIC_REGISTRATION)
            ),
            generatePanel(
                RequestStepsView.Corporate_Transparency,
                'CorporateTransparency',
                !ctaAutoSubmit &&
                    !isEntityDissolved &&
                    ((hasCapability(Capabilities.createCorporateTransparency) &&
                        entityCreationState === CREATE_ENTITY_STATE.CORPORATE_TRANSPARENCY_REQUEST) ||
                        (hasCapability(Capabilities.approveCorporateTransparency) && isCtaReview))
            ),
            generatePanel(
                RequestStepsView.Approval,
                'Approval',
                hasCapability(Capabilities.approveApproval) && entityCreationState === CREATE_ENTITY_STATE.APPROVAL
            ),
        ];

        return creationStepPanels;
    };

    const getDissolutionStepPanels = (entityCreationWorkItem?: CreateLegalEntityWorkflow | undefined) => {
        if (
            hasCapability(Capabilities.viewDissolutionRequest) &&
            isDissolutionStepEnabled(entityCreationWorkItem, legalEntityOId)
        ) {
            const dissolutionStepPanel: RequestStepPanel[] = [
                generatePanel(
                    RequestStepsView.Dissolution_Details,
                    'DissolutionDetails',
                    hasCapability(Capabilities.createDissolutionRequest) &&
                        legalEntityDissolutionParentWorkItem?.workItemState !==
                            DISSOLUTION_STATE.DISSOLUTION_APPROVAL &&
                        legalEntityDissolutionParentWorkItem?.workItemState !== DISSOLUTION_STATE.SAVE_DATA &&
                        legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS
                ),
            ];

            const hasNewApprovalTask = legalEntityDissolutionParentWorkItem?.tasks?.find(
                (task) => task.taskType === 'ApprovalRequestType' && task.status === 'New'
            );

            const approvalTasks = sortBy(
                (legalEntityDissolutionParentWorkItem?.tasks || []).filter(
                    (task) => task.taskType === 'ApprovalRequestType'
                ),
                ['taskId']
            );
            const latestApprovalTask = approvalTasks[approvalTasks.length - 1];
            const hasCompletedApprove =
                latestApprovalTask?.status === 'Complete' && latestApprovalTask?.exitCode === 'Approve';

            if (hasNewApprovalTask || hasCompletedApprove) {
                dissolutionStepPanel.push(
                    generatePanel(
                        RequestStepsView.Dissolution_Approval,
                        'DissolutionApproval',
                        hasCapability(Capabilities.approveDissolutionRequest) &&
                            legalEntityDissolutionParentWorkItem?.workItemState ===
                                DISSOLUTION_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Approval)
                    )
                );
            }

            if (hasCompletedApprove) {
                dissolutionStepPanel.push(
                    generatePanel(
                        RequestStepsView.Dissolution_Litigation,
                        'DissolutionLitigation',
                        hasCapability(Capabilities.approveDissolutionLitigation) &&
                            legalEntityDissolutionLitigationWorkItem?.workItemState ===
                                DISSOLUTION_LITIGATION_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Litigation)
                    ),
                    generatePanel(
                        RequestStepsView.Dissolution_Tax,
                        'DissolutionTax',
                        hasCapability(Capabilities.approveDissolutionTax) &&
                            legalEntityDissolutionTaxWorkItem?.workItemState ===
                                DISSOLUTION_TAX_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Tax)
                    ),
                    generatePanel(
                        RequestStepsView.Dissolution_Finance,
                        'DissolutionFinance',
                        hasCapability(Capabilities.approveDissolutionFinance) &&
                            legalEntityDissolutionFinanceWorkItem?.workItemState ===
                                DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Finance)
                    ),
                    generatePanel(
                        RequestStepsView.Dissolution_Legal,
                        'DissolutionLegal',
                        hasCapability(Capabilities.approveDissolutionLegal) &&
                            legalEntityDissolutionLegalWorkItem?.workItemState ===
                                DISSOLUTION_LEGAL_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Legal)
                    ),
                    generatePanel(
                        RequestStepsView.Dissolution_Treasury,
                        'DissolutionTreasury',
                        hasCapability(Capabilities.approveDissolutionTreasury) &&
                            legalEntityDissolutionFinanceWorkItem?.workItemState ===
                                DISSOLUTION_FINANCE_STATE.DISSOLUTION_APPROVED &&
                            legalEntityDissolutionTreasuryWorkItem?.workItemState ===
                                DISSOLUTION_TREASURY_STATE.DISSOLUTION_APPROVAL,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_Treasury)
                    ),
                    generatePanel(
                        RequestStepsView.Dissolution_BDG,
                        'DissolutionBDG',
                        hasCapability(Capabilities.approveDissolutionBDG) &&
                            legalEntityDissolutionParentWorkItem?.workItemState ===
                                DISSOLUTION_STATE.BDG_PENDING_SUBMIT,
                        getSidebarCustomIcon(RequestStepsView.Dissolution_BDG)
                    )
                );
            }

            return dissolutionStepPanel;
        }

        return [] as RequestStepPanel[];
    };

    const sideBarItems = useMemo(() => {
        if (!isSupportedEntity) {
            return [];
        }

        const creationStepPanels = getCreationStepPanels(entityCreationWorkItem);
        const dissolutionStepPanels = getDissolutionStepPanels(entityCreationWorkItem);

        const isAfterMainApproval = !!entityCreationWorkItem?.tasks?.some(
            (task) => task.taskType === 'ActivateEntity' && task.status === 'Complete'
        );

        const foreignRegistrationWorkSection = () => {
            const isThereUncompletedForeignRegChildWorkflow =
                foreignRegistrationWorkItems.length &&
                foreignRegistrationWorkItems.some(({ workItemStatus }) => workItemStatus !== 'Complete');

            const isForeignRegistrationUpdate =
                isAfterMainApproval &&
                !isThereUncompletedForeignRegChildWorkflow &&
                hasCapability(Capabilities.updateForeignRegistration);

            return isForeignRegistrationUpdate ? UserScopes.ForeignRegistrationUpdate : UserScopes.ForeignRegistration;
        };

        const isCtaCreation =
            !ctaAutoSubmit &&
            hasCapability(Capabilities.createCorporateTransparency) &&
            !!entityCreationWorkItem?.tasks?.some(
                (task) => task.taskType === 'CorporateTransparencyTaskType' && task.status === 'New'
            );

        const ctaReviewStep = isAfterMainApproval
            ? [
                  generatePanel(
                      RequestStepsView.Corporate_Transparency,
                      'CorporateTransparency',
                      !isEntityDissolved && (isCtaCreation || isCtaReview)
                  ),
              ]
            : [];

        let panels: RequestStepPanel[] = [
            ...creationStepPanels,
            ...ctaReviewStep,
            generatePanel(
                RequestStepsView.Foreign_Registration,
                foreignRegistrationWorkSection(),
                !isEntityDissolved &&
                    (foreignRegistrationWorkItems.some((f) => f.workItemStatus === 'InProcess') ||
                        foreignRegistrationUpdateWorkItem?.workItemStatus === 'InProcess') &&
                    ((foreignRegistrationWorkItems.some((f) => f.workItemState === 'RegistrationRequest') &&
                        hasCapability(Capabilities.createForeignRegistration)) ||
                        (foreignRegistrationUpdateWorkItem?.workItemState === 'ForeignRegistrationUpdateRequest' &&
                            hasCapability(Capabilities.updateForeignRegistration)) ||
                        ((foreignRegistrationWorkItems.some((f) => f.workItemState === 'RegistrationApproval') ||
                            foreignRegistrationUpdateWorkItem?.workItemState === 'ForeignRegistrationUpdateApproval') &&
                            hasCapability(Capabilities.approveForeignRegistration)))
            ),
            generatePanel(
                RequestStepsView.Tax_Classification,
                'TaxClassification',
                !isEntityDissolved &&
                    taxClassificationWorkItem?.workItemStatus !== 'Canceled' &&
                    ((hasCapability(Capabilities.approveTaxClassification) &&
                        taxClassificationWorkItem?.workItemState === 'TaxApproval') ||
                        (hasCapability(Capabilities.createTaxClassification) &&
                            taxClassificationWorkItem?.workItemState === 'TaxRequest'))
            ),
            generatePanel(
                RequestStepsView.Finance_Details,
                'FinanceInformation',
                !isEntityDissolved &&
                    financeInformationWorkItem?.workItemStatus !== 'Canceled' &&
                    ((hasCapability(Capabilities.approveFinanceInformation) &&
                        financeInformationWorkItem?.workItemState === 'FinanceApproval') ||
                        (hasCapability(Capabilities.createFinanceInformation) &&
                            financeInformationWorkItem?.workItemState === 'FinanceRequest'))
            ),
            generatePanel(
                RequestStepsView.Ownership_Information,
                'OwnershipInformation',
                !isEntityDissolved &&
                    ownershipWorkItem?.workItemStatus !== 'Canceled' &&
                    ((hasCapability(Capabilities.approveOwnershipInformation) &&
                        ownershipWorkItem?.workItemState === 'OwnershipApproval') ||
                        (hasCapability(Capabilities.createOwnershipInformation) &&
                            ownershipWorkItem?.workItemState === 'OwnershipRequest'))
            ),
            ...dissolutionStepPanels,
        ];

        if (!hasViewAllRole) {
            panels = panels?.filter((panel: RequestStepPanel) => checkPanelIsViewable(panel)) ?? [];
        }

        if (
            !isInformationStepEnabled(entityCreationWorkItem, legalEntityOId) &&
            panels.length > 0 &&
            !panels.some((panel) => panel.requestStepView === RequestStepsView.Request)
        ) {
            const request = generatePanel(
                RequestStepsView.Request,
                'Details',
                isNil(entityCreationWorkItem?.workItemState) ||
                    (entityCreationWorkItem?.workItemState === 'Update Request' &&
                        hasCapability(Capabilities.createLegalInformation))
            );
            panels.splice(0, 0, request);
        }

        const path = historyApi.location.pathname;
        const section = path.substring(path.lastIndexOf('/') + 1);
        const isDissolutionInProcess =
            legalEntityDissolutionParentWorkItem?.workItemStatus === DISSOLUTION_STATUS.IN_PROCESS;
        const CTARedirectNotNeeded = !isCtaReview || (isCtaReview && isDissolutionInProcess);

        const panel =
            CTARedirectNotNeeded &&
            panels.find(
                (p) => p.requestStepView.replace(/\s/g, '').toLocaleLowerCase() === section.toLocaleLowerCase()
            );

        if (!isStandalone) {
            if (panel) setLegalEntityCreationSelectedPanel(dispatch, panel);
            else {
                if (panels.length > 0) {
                    const panelWithAction = panels.find((p) => p.hasActions);
                    setLegalEntityCreationSelectedPanel(dispatch, panelWithAction);
                    if (legalEntityOId) reloadPage(legalEntityOId, 1000, panelWithAction?.requestStepView, false);
                }
            }
        }

        return panels;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isCtaReview,
        isSupportedEntity,
        ctaAutoSubmit,
        entityCreationWorkItem,
        financeInformationWorkItem,
        ...foreignRegistrationWorkItems,
        foreignRegistrationUpdateWorkItem,
        ownershipWorkItem,
        taxClassificationWorkItem,
        userScopes,
        showSubmitButton,
        legalEntityDissolutionParentWorkItem,
        legalEntityDissolutionFinanceWorkItem,
        legalEntityDissolutionTreasuryWorkItem,
        legalEntityDissolutionTaxWorkItem,
        legalEntityDissolutionLitigationWorkItem,
        legalEntityDissolutionLegalWorkItem,
    ]);

    useEffect(() => {
        setSideBarLength(dispatch, sideBarItems.length);
    }, [sideBarItems, dispatch]);

    return sideBarItems;
}
