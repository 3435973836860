import 'Utilities/DateExtender';

export function convertToLocalDate(value?: Date | string) {
    return value ? new Date(value).utcToLocalDate() : undefined;
}

export function subtractMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() - months);
    return date;
}

export function subtractDays(date: Date, days: number) {
    date.setDate(date.getDate() - days);
    return date;
}

export function calcDaysSinceToday(value: string): number {
    const givenDate = new Date(value);
    const givenTime = Date.UTC(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate(), 0, 0, 0, 0);
    const now = new Date();
    const today = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const day = 1000 * 60 * 60 * 24;
    return Math.round((today - givenTime) / day);
}

export function getDateMMDDYYYY(value?: string) {
    if (!value) {
        return '';
    }

    const date = new Date(value);

    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);
}
