import { StackPanel as StackPanelGC, StackPanelProps } from '@bxgrandcentral/controls';

type Props = StackPanelProps;

export const StackPanel = (props: Props) => {
    const { children, styleOverrides, ...restProps } = props;

    return (
        <StackPanelGC {...restProps} styleOverrides={{ justifyContent: 'flex-start', ...styleOverrides }}>
            {children}
        </StackPanelGC>
    );
};
