import {
    CreateLegalEntityWorkflow,
    LegalEntityDissolutionDetailsWorkflow,
} from '../../models/LegalEntityRequest/Workflow';

import { GenericWorkflow } from 'models/LegalEntityRequest/Workflow/GenericWorkflow';
import { RecursivePartial } from '../../Utilities/ReflectionUtil';
import { Task } from '../../models/Workflow';
import { WorkItem } from '../../models/Workflow/WorkItem';

export type workItemUpdate = Pick<WorkItem, 'workItemId' | 'workItemVersionNumber'> & RecursivePartial<WorkItem>;
export type GetWorkItemForEntityResponse = {
    workItem?: CreateLegalEntityWorkflow;
    childItems: WorkItem[];
    standaloneItems: WorkItem[];
    dissolutionWorkflow?: DissolutionWorkflow;
};
export type DissolutionWorkflow = {
    workItem?: LegalEntityDissolutionDetailsWorkflow;
    childItems: WorkItem[];
};

export abstract class IWorkflowService {
    abstract UpdateWorkItem(workItemDto: workItemUpdate): Promise<GenericWorkflow>;
    abstract CreateNewEntityWorkItem(entityOId: number): Promise<CreateLegalEntityWorkflow>;
    abstract CreateNewEntityWorkItemStandalone(entityOId: number, workItemType: string): Promise<GenericWorkflow>;
    abstract GetWorkItemForEntity(entityOId: number): Promise<GetWorkItemForEntityResponse>;
    abstract CompleteTask(
        task: Task,
        workItem: WorkItem,
        exitCode?: string,
        rejectReason?: string
    ): Promise<void>;
}
