import { EmailPreferences } from 'models/UserProfile/EmailPreferences';
import { CtaEmailPreferences } from 'models/UserProfile/CtaEmailPreferences';
import { CreationEmailPreferences } from 'models/UserProfile/CreationEmailPreferences';
import { EmailPreferenceCategory } from 'models/UserProfile/EmailPreferenceCategory';

export abstract class IUserProfileService {
    public abstract LoadCreationEmailPreferences(): Promise<CreationEmailPreferences>;
    public abstract SaveCreationEmailPreferences(settings: CreationEmailPreferences): Promise<any>;
    public abstract LoadCtaEmailPreferences(): Promise<CtaEmailPreferences>;
    public abstract SaveCtaEmailPreferences(settings: CtaEmailPreferences): Promise<any>;
    public abstract LoadEmailPreferences(category: EmailPreferenceCategory): Promise<EmailPreferences>;
    public abstract SaveEmailPreferences(category: EmailPreferenceCategory, settings: EmailPreferences): Promise<any>;
}
