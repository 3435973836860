import React, { useEffect } from 'react';
import { StackPanel, TextBlock, ToolBarButton } from '@bxgrandcentral/controls';

import { BooleanField } from 'components';
import { CtaEmailPreferences } from 'models/UserProfile/CtaEmailPreferences';
import { IUserProfileService } from 'services/interfaces/IUserProfileService';
import { SPACING } from 'Utilities/Layout';
import { ServiceLocator } from '@bxgrandcentral/shell';
import useColors from 'api/hooks/use-theme';
import { Component, Content, Header } from './Common';

interface Props {
    title: string;
}

export default function CtaSubSettings({ title }: Props) {
    const userService = ServiceLocator.container.resolve(IUserProfileService);
    const [settings, setSettings] = React.useState<CtaPreferencesResult>(CtaPreferencesResult.Fail());

    const { accentColor } = useColors();

    useEffect(() => {
        userService
            .LoadCtaEmailPreferences()
            .then((result) => {
                setSettings(CtaPreferencesResult.Success(result));
            })
            .catch((e) => {
                console.log('Error: ' + e.toString());
            });
    }, [userService]);

    return (
        <Component>
            <Header>
                <ToolBarButton foregroundColor={accentColor} fontSize={18} icon={''} />
                <TextBlock fontSize={18} text={title} margin={`${SPACING.XXS}px 0 0 0`} />
                <BooleanField
                    trueValueLabel=''
                    falseValueLabel=''
                    variant='dashboard'
                    backgroundColor='none'
                    value={settings.Preferences.Enabled}
                    canResetValue={false}
                    canUndo={false}
                    onValueChanged={async (newValue = false) => {
                        const newSettings = { ...settings };
                        newSettings.Preferences.Enabled = newValue;
                        await userService.SaveCtaEmailPreferences(newSettings.Preferences);
                        setSettings(newSettings);
                    }}
                />
            </Header>
            <StackPanel styleOverrides={{ display: 'none' }}>
                <Content>
                    <StackPanel itemGap={SPACING.XXS}></StackPanel>
                </Content>
            </StackPanel>
        </Component>
    );
}

class CtaPreferencesResult {
    public Preferences: CtaEmailPreferences = new CtaEmailPreferences();
    public IsInitialized: boolean = false;
    public IsSuccess: boolean = true;
    constructor(preferences: CtaEmailPreferences, isInitialized: boolean, isSuccess: boolean) {
        this.Preferences = preferences;
        this.IsInitialized = isInitialized;
        this.IsSuccess = isSuccess;
    }

    static Default() {
        return new CtaPreferencesResult(new CtaEmailPreferences(), false, true);
    }

    static Success(preferences: CtaEmailPreferences) {
        if (preferences === undefined) {
            preferences = new CtaEmailPreferences();
        }
        return new CtaPreferencesResult(preferences, true, true);
    }

    static Fail() {
        return new CtaPreferencesResult(new CtaEmailPreferences(), false, false);
    }
}
