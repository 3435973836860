import {
    BooleanFieldProps,
    BooleanField as GrandCentralBooleanField,
    Grid,
    TextBlock,
    ToolTipService,
} from '@bxgrandcentral/controls';

import { FormControl } from '../controls.styled';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import useColors from 'api/hooks/use-theme';

type Props = BooleanFieldProps & {
    version?: number;
    labelToolTip?: {
        component: any;
        options?: ToolTipService;
    };
    isPendingChange?: boolean;
    variant?: 'dashboard' | 'form';
    bottomDescription?: string;
    height?: number;
    titleHeight?: number;
};

export default function BooleanField({
    isEditable,
    isRequired,
    validationError,
    version = 0,
    labelToolTip,
    label,
    value = false,
    variant = 'form',
    isPendingChange,
    onValueChanged,
    bottomDescription,
    height,
    titleHeight,
    ...rest
}: Props) {
    const { accentColor } = useColors();

    return (
        <FormControl className={`${variant} booleanField${value ? 'On' : 'Off'}`} height={height}>
            <Grid columnDefinitions='auto * auto' rowDefinitions='*'>
                <>
                    <TextBlock
                        styleName='fieldLabelStyle'
                        toolTip={labelToolTip?.component}
                        toolTipService={labelToolTip?.options}
                        text={label}
                        margin={`0 0 ${SPACING.XXS}px 0`}
                        height={titleHeight}
                    />
                    {!isEditable && isPendingChange && (
                        <TextBlock
                            text='PENDING'
                            fontSize='16px'
                            styleName='fieldLabelStyle'
                            foregroundColor={accentColor}
                            textAlignment='right'
                        />
                    )}
                </>
            </Grid>

            <GrandCentralBooleanField
                key={version}
                isEditable={isEditable}
                isRequired={isRequired && isEditable}
                canResetValue={false}
                canUndo={isEditable}
                value={value}
                onValueChanged={(newValue = false) => {
                    onValueChanged && onValueChanged(newValue);
                }}
                {...(isEditable && isRequired && { validationError })}
                {...rest}
            />

            {bottomDescription && (
                <TextBlock styleName='fieldLabelStyle' padding={`${SPACING.XS}px 0 0 0`}>
                    {bottomDescription}
                </TextBlock>
            )}
        </FormControl>
    );
}
