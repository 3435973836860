import { DateTimeField, ElasticSearchField, Select, Text } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import React, { useEffect, useMemo } from 'react';
import { StackPanel, TextBlock } from '@bxgrandcentral/controls';
import { isEqual, isNil } from 'lodash';
import {
    useLegalEntityAuthorizedPerson,
    useLegalEntityOwnerShipInformation,
} from 'modules/LegalEntityCreation/context/Provider';

import { AuthorizedPersonModel } from '../../model';
import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import UpdateButton from '../OwnershipDetails/UpdateButton';
import { convertToLocalDate } from 'Utilities/date';
import { getValuesOf } from 'Utilities/object';
import { isEmpty } from 'Utilities/Validations';
import { resetState } from 'modules/LegalEntityCreation/context/actions';
import useAuthorizedPersonEditValidation from 'modules/LegalEntityCreation/validation/use-authorized-person-edit-validation';
import { useReferenceData } from 'api';

type Props = {
    addNewItem: (item: AuthorizedPersonModel) => void;
    closeSelectedElement: () => void;
    isNewFlow: boolean;
    setFormHasChanges: (isOpen: boolean) => void;
};

export default function AuthorizedPerson({ addNewItem, closeSelectedElement, isNewFlow, setFormHasChanges }: Props) {
    const {
        state: {
            data: {
                values: { authorizedPerson = [] },
            },
            isEditable,
            version,
        },
        setValue: setAuthorizedPersonValue,
    } = useLegalEntityOwnerShipInformation();

    const {
        state: {
            data: { values, noPendingValues, validationErrors, changes },
        },
        setValue,
        setValues,
        getValue,
        dispatch,
    } = useLegalEntityAuthorizedPerson();

    useAuthorizedPersonEditValidation();

    const {
        data: { SignatoryTitles },
    } = useReferenceData();

    const { key, index, relatedEntityId, title, appointmentDate, resignationDate, hasPendingChange } = values;

    const isEmptyAuthorizedPerson = useMemo(
        () =>
            getValuesOf({
                relatedEntityId,
                title,
                appointmentDate,
                resignationDate,
            }).every((value) => isNil(value)),
        [relatedEntityId, title, appointmentDate, resignationDate]
    );

    const hasNoValidationError = useMemo(
        () =>
            isNil(validationErrors.relatedEntityId) &&
            isNil(validationErrors.title) &&
            isNil(validationErrors.appointmentDate) &&
            isNil(validationErrors.resignationDate),
        [validationErrors]
    );

    const isUnfinishedRegistration = useMemo(
        () => isNil(relatedEntityId) || isNil(title) || isNil(appointmentDate),
        [relatedEntityId, title, appointmentDate]
    );

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    const addChanges = () => {
        if (values.key || !isNewFlow) {
            const updated = authorizedPerson.map((person) => {
                if (person.index === values.index) {
                    return values;
                }

                return person;
            });

            setAuthorizedPersonValue('authorizedPerson', updated);
            closeSelectedElement();
        } else {
            addNewItem(values);
        }
        resetState(dispatch);
    };

    useEffect(() => {
        setFormHasChanges(!isEmpty(changes));
    }, [changes, values]);

    const itemIsSetForDeletion = useMemo(() => {
        const editedItem = authorizedPerson.find((item) => item.index === index);

        return editedItem?.isDeleted;
    }, [authorizedPerson]);

    const updateButtonAvailable = isEditable && !itemIsSetForDeletion;
    const isNewItem = !key || (hasPendingChange && !noPendingValues);

    return (
        <StackPanel padding={HORIZONTAL_FORM_PADDING}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Person
                </TextBlock>
            )}
            <DoubleColumnLayout isDisabled={itemIsSetForDeletion}>
                <StackPanel itemGap={SPACING.SM}>
                    <FormControl className='form' height={120}>
                        <ElasticSearchField
                            label='Name'
                            labelToolTip='Enter the name of the authorized person'
                            searchType={EntitySubType.ThirdParty}
                            selectedEntityOId={relatedEntityId}
                            onEntitySelected={(result) => {
                                setValues({
                                    relatedEntityId: result?.entityOId,
                                    relatedEntityName: result?.primaryName,
                                });
                            }}
                            canClearSelection
                            isReadOnly={!isEditable}
                            isPendingChange={isPendingChange(relatedEntityId, noPendingValues?.relatedEntityId)}
                            isRequired
                            {...(isEditable && { validationError: validationErrors.relatedEntityId })}
                        />
                    </FormControl>
                    <Select
                        label='Title'
                        labelToolTip={{ component: 'Select the authorized person’s title' }}
                        itemsSource={SignatoryTitles}
                        isRequired
                        {...getValue('title')}
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <FormControl className='form' height={120}>
                        <DateTimeField
                            label='Appointment Date'
                            labelToolTip='Date in which authorized person was appointed'
                            value={appointmentDate}
                            isEditable={isEditable}
                            version={version}
                            validationError={validationErrors.appointmentDate}
                            onValueChanged={(newValue) => {
                                setValue('appointmentDate', convertToLocalDate(newValue));
                            }}
                            isPendingChange={isPendingChange(
                                appointmentDate?.toString(),
                                noPendingValues?.appointmentDate?.toString()
                            )}
                            isRequired
                        />
                    </FormControl>
                    <DateTimeField
                        label='Resignation Date'
                        labelToolTip='Date of resignation'
                        value={resignationDate}
                        isEditable={isEditable}
                        version={version}
                        validationError={validationErrors.resignationDate}
                        onValueChanged={(newValue) => {
                            setValue('resignationDate', convertToLocalDate(newValue));
                        }}
                        isPendingChange={isPendingChange(
                            resignationDate?.toString(),
                            noPendingValues?.resignationDate?.toString()
                        )}
                    />
                </StackPanel>
            </DoubleColumnLayout>
            <StackPanel itemGap={SPACING.MD}>
                {isUnfinishedRegistration && (
                    <Text
                        horizontalAlignment='center'
                        verticalAlignment='center'
                        textAlignment='center'
                        variant='errorBold'>
                        Unfinished Authorized Person registration can't be saved, please set name, title and appointment
                        date
                    </Text>
                )}
                {updateButtonAvailable && (
                    <StackPanel
                        itemGap={SPACING.LG}
                        orientation='horizontal'
                        styleOverrides={{ alignSelf: 'flex-end' }}>
                        <UpdateButton
                            addChanges={addChanges}
                            isEnabled={hasNoValidationError && !isEmptyAuthorizedPerson}
                            text={isNewFlow ? 'Add' : 'Update Authorized Person'}
                        />
                    </StackPanel>
                )}
            </StackPanel>
        </StackPanel>
    );
}
