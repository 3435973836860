import PermissionInfo from 'components/PermissionInfo/PermissionInfo';
import React from 'react';
import { SPACING } from 'Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';
import { Text } from 'components';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useCreationViewContext } from '../../context/creation-view-context';

export default function NoPermissionInfo() {
    const {
        state: { loadingStates },
    } = useCreationViewContext();

    return (
        <Wrapper hidden={!isEmpty(loadingStates)}>
            <>
                <StackPanel padding={`0 ${SPACING.XXL}px ${SPACING.LG}px ${SPACING.XXL}px`}>
                    <Text variant='body-bold'>
                        You do not have access to this entity because your business unit or firm is not tagged to this
                        entity or you do not have access to any sections.
                    </Text>
                </StackPanel>
                <StackPanel>
                    <Text variant='body'>
                        Please contact&nbsp;
                        <a href='mailto:BDGLegalEntityManagement@Blackstone.com'>
                            BDGLegalEntityManagement@Blackstone.com
                        </a>
                        &nbsp;to request for access.
                    </Text>
                </StackPanel>
                <PermissionInfo />
            </>
        </Wrapper>
    );
}

export const Wrapper = styled.div<{ hidden: boolean }>`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;
