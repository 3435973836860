import { Address, EntityNames, TaxCode } from 'models';
import { LegalEntityUpdateTask, LegalEntityUpdateWorkflow } from '../../../models/LegalEntityRequest/Workflow';

import { GenericWorkflow } from 'models/LegalEntityRequest/Workflow/GenericWorkflow';

export type OnSaveAction = (data: Partial<LegalEntityInformationData>) => Promise<number>;
export type OnCompleteTaskAction = (task: LegalEntityUpdateTask, exitCode: string, rejectReason?: string) => void;
export type OnCreateWorkItem = (entityId: number, workflowType: string) => Promise<GenericWorkflow | undefined>;

export type LegalEntityInformationData = {
    entityOid?: number;
    legalName?: string;
    shortName?: string;
    businessDriverId?: number;
    businessUnitOId?: number;
    subBusinessUnitOId?: number;
    projectEntityOId?: number;
    legalTypeId?: number;
    structureType?: number;
    isOldStructureType?: boolean;
    administeredBy?: number;
    administratorHKAPACOId?: number;
    externalAdminOId?: number;
    portfolioId?: number;
    externalAdminContact?: number;
    lawFirmEntityOId?: number;
    isBlueprintEntity?: boolean;
    isOrderChop?: boolean;
    isOrderSeal?: boolean;
    remarks?: string;
    financialYearEndDate?: Date;
    isDisregardedEntity?: boolean;
    hedgehogID?: string;
    RegistrationData?: RegistrationData[];
    addresses?: Address[];
    taxCodes?: TaxCode[];
    entityNames?: EntityNames[];
    madeInNewSystems?: boolean;
    taxAuditorOId?: number;
    legalStatusId?: number;
    associatedDeal?: number;
    dealCountries?: string[];
    domainStageID?: string;
};

export type RegistrationData = {
    taxRegistrationId?: number;
    domesticRegisteredAgent?: number;
    domesticCountry?: number;
    domesticState?: number;
    registrationNumber?: string;
    formationDate?: Date;
    dissolutionDate?: Date;
    isDomestic?: boolean;
    isDeleted?: boolean;
};

type BaseProps = {
    data?: LegalEntityInformationData;
};

type noPendingValues = {
    noPendingData?: LegalEntityInformationData | undefined;
};

type ActionPanelBaseProps = {
    entityOId?: number;
};

type ReadOnlyProps = {
    mode: 'Readonly';
    workItem?: LegalEntityUpdateWorkflow;
};

type CreateProps = {
    mode: 'Create';
    workItem?: LegalEntityUpdateWorkflow;
    onSave: OnSaveAction;
    onCreateWorkItem: OnCreateWorkItem;
};

type ApprovalProps = {
    mode: 'Approval';
    workItem: LegalEntityUpdateWorkflow;
    onCompleteTask: OnCompleteTaskAction;
};

type EditProps = {
    mode: 'Edit';
    workItem?: LegalEntityUpdateWorkflow;
    onSave: OnSaveAction;
    onCompleteTask: OnCompleteTaskAction;
};

export type LegalEntityInformationProps = BaseProps &
    noPendingValues &
    (CreateProps | EditProps | ApprovalProps | ReadOnlyProps);

export type ActionPanelProps = ActionPanelBaseProps & LegalEntityInformationProps;

export const addressTypes = {
    headquarterAddress: { Key: 1, Value: 'Headquarter Address' },
    legalAddress: { Key: 2, Value: 'Legal Address' },
    registeredOfficeAddress: { Key: 5, Value: 'Registered Office Address' },
};
