import { EmailPreferenceCategory } from 'models/UserProfile/EmailPreferenceCategory';
import EmailSubSettings from './EmailSubSettings';
import { SPACING } from 'Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';
import { Text } from 'components';
import CtaSubSettings from './CtaSubSettings';
import CreationSubSettings from './CreationSubSettings';

export default function EmailSettings() {
    return (
        <StackPanel
            padding={`${SPACING.XS}px ${SPACING.XS}px`}
            styleOverrides={{ justifyContent: 'flex-start', width: '400px' }}>
            <StackPanel itemGap={SPACING.XXS} styleOverrides={{ overflowY: 'auto' }}>
                <div>
                    <Text
                        fontSize={12}
                        variant='body'
                        styleOverrides={{
                            margin: `${SPACING.SM}px ${SPACING.MD}px 0 ${SPACING.MD}px`,
                            display: 'block',
                        }}>
                        Toggle Yes to turn on Email Notifications. Toggle No to turn off Email Notifications.
                    </Text>
                </div>
                <CreationSubSettings title='Legal Entity Creation' />
                <EmailSubSettings
                    category={EmailPreferenceCategory.LegalEntityInformation}
                    title={'Legal Entity Information'}
                />
                <EmailSubSettings category={EmailPreferenceCategory.FinanceDetails} title={'Finance Details'} />
                <EmailSubSettings category={EmailPreferenceCategory.TaxInformation} title={'Tax Information'} />
                <EmailSubSettings
                    category={EmailPreferenceCategory.OwnershipInformation}
                    title={'Ownership Information'}
                />
                <EmailSubSettings
                    category={EmailPreferenceCategory.ForeignRegistration}
                    title={'Foreign Registration'}
                />
                <CtaSubSettings title='CTA' />
            </StackPanel>
        </StackPanel>
    );
}
