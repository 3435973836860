import { BusinessUnit, Countries, States, isUnitedStates } from 'models';
import {
    CRSClassifications,
    CRSSubClassificationDetails,
    CRSSubClassifications,
    FATCAClassifications,
    LegalEntityTaxClassificationData,
    USStateTaxClassificationTypes,
    USTaxClassificationTypes,
    allowedUSTaxClassificationTypeKey,
    getChapterThreeOptions,
    isBlockerStructureType,
} from '../model';
import { isEmpty, isNil } from 'lodash';

import { useCallback } from 'react';
import { useReferenceData } from 'api';

export default function useLegalEntityTaxClassificationAutoPopulateData() {
    const {
        data: { ChapterThreeStatus },
    } = useReferenceData();

    const isCaymanIslandOrDelaware = useCallback(
        ({ domesticCountry, domesticState }: LegalEntityTaxClassificationData) =>
            domesticCountry === CaymanIslands || (domesticCountry === UnitedStates && domesticState === Delaware),
        []
    );

    const getFatcaClassificationId = useCallback(({ domesticCountry }: LegalEntityTaxClassificationData) => {
        switch (domesticCountry) {
            case UnitedStates: {
                return USFI;
            }
            case CaymanIslands:
            case HongKong:
            case Japan: {
                return ParticipatingFFI;
            }
            case Canada:
            case Luxembourg:
            case Mauritius:
            case Singapore:
            case Australia:
            case China:
            case India:
            case UnitedKingdom:
            case VirginIslandsBritish: {
                return ReportingModel1FFI;
            }
            default: {
                return undefined;
            }
        }
    }, []);

    const getIsCtbElectionApproved = useCallback(
        (data: LegalEntityTaxClassificationData) => {
            const { businessUnitOId, domesticFormationDate } = data;

            if (!isNil(domesticFormationDate)) {
                return true;
            }

            if (isCaymanIslandOrDelaware(data)) {
                return isBlockerStructureType(data) ? businessUnitOId !== BusinessUnit.RealEstate : false;
            }

            return false;
        },
        [isCaymanIslandOrDelaware]
    );

    const getChapterThreeStatusIdDefaultValue = useCallback(
        (usTaxClassificationTypeId?: number, domesticCountry?: number) => {
            const allowedOptions = getChapterThreeOptions(
                usTaxClassificationTypeId as allowedUSTaxClassificationTypeKey,
                domesticCountry
            );

            const chapterThreeStatusFiltered = ChapterThreeStatus?.filter(({ Key }) => allowedOptions.includes(Key));

            return chapterThreeStatusFiltered?.length === 1 ? chapterThreeStatusFiltered[0].Key : undefined;
        },
        []
    );

    const usTaxClassifications = useCallback(
        (data: LegalEntityTaxClassificationData) => {
            if (isCaymanIslandOrDelaware(data)) {
                if (isBlockerStructureType(data)) {
                    return {
                        usTaxClassificationTypeId: Corporation,
                        usStateTaxClassificationTypeId: CorporationState,
                        chapterThreeStatusId: getChapterThreeStatusIdDefaultValue(Corporation, data.domesticCountry),
                    };
                } else {
                    return {
                        usTaxClassificationTypeId: Partnership,
                        usStateTaxClassificationTypeId: PartnershipState,
                        chapterThreeStatusId: getChapterThreeStatusIdDefaultValue(Partnership, data.domesticCountry),
                    };
                }
            }
        },
        [isCaymanIslandOrDelaware]
    );

    const calculateDefaultValues = useCallback(
        (data: LegalEntityTaxClassificationData | undefined) => {
            if (isNil(data)) {
                return {};
            }
            const {
                crsClassificationId,
                domesticCountry,
                domesticFormationDate,
                isCtbElectionApproved: storedIsCtbElectionApproved,
            } = data;

            const isCtbElectionApproved = storedIsCtbElectionApproved ?? getIsCtbElectionApproved(data);

            return {
                fatcaClassificationId: getFatcaClassificationId(data),
                isCtbElectionApproved,
                ...(isUnitedStates(domesticCountry) && isEmpty(crsClassificationId)
                    ? {
                          crsClassificationId: FINonParticipatingJurisdiction,
                          crsSubClassificationId: OtherFI,
                          crsSubClassificationDetailId: NoneOfTheAbove,
                      }
                    : {
                          crsClassificationId: ReportingFI,
                      }),
                isGIINRequired: !isUnitedStates(domesticCountry) && crsClassificationId !== ActiveOrPassiveNFFE,
                ...(isCtbElectionApproved && { tax8832EffectiveDate: domesticFormationDate }),
                ...usTaxClassifications(data),
            };
        },
        [getFatcaClassificationId, getIsCtbElectionApproved, usTaxClassifications]
    );

    return { calculateDefaultValues };
}

const {
    UnitedStates,
    HongKong,
    Japan,
    Canada,
    CaymanIslands,
    Luxembourg,
    Mauritius,
    Singapore,
    Australia,
    China,
    India,
    UnitedKingdom,
    VirginIslandsBritish,
} = Countries;
const { Delaware } = States;
const { USFI, ReportingModel1FFI, ParticipatingFFI } = FATCAClassifications;
const { Corporation, Partnership } = USTaxClassificationTypes;
const { Corporation: CorporationState, Partnership: PartnershipState } = USStateTaxClassificationTypes;
const { ActiveOrPassiveNFFE } = FATCAClassifications;
const { NoneOfTheAbove } = CRSSubClassificationDetails;
const { FINonParticipatingJurisdiction, ReportingFI } = CRSClassifications;
const { OtherFI } = CRSSubClassifications;
