import {
    BooleanField,
    Debugger,
    ElasticSearchField,
    Expander,
    Footer,
    Form,
    RequestNotes,
    Select,
    StackPanel,
    TextField,
} from 'components';
import { DoubleColumnLayout, SPACING } from 'Utilities/Layout';
import { GPEntityTier as GPEntityTierData, GPInterestType as GPInterestTypeData } from '../../../models/index';
import { Investran, KeyValueData } from '../../../models';
import { getBillingValue, getDefaultInvestranDomain, getRepaymentGroupByBUId } from './utils';
import { intersectionBy, isEmpty, isEqual, isNil, isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ActionPanel from './components/ActionPanel/ActionPanel';
import { ContentBox } from 'modules/RequestsDashboard/components/components.styled';
import { EntitySubType } from 'models/LegalEntity/EntitySubType';
import { FormControl } from 'components/controls/controls.styled';
import { LegalEntityFinanceDetailsProps } from './model';
import PageUpdateInfo from '../LegalEntityDissolution/components/PageUpdateInfo';
import RejectReason from '../Shared/RejectReason';
import ThirdPartyUserSelect from 'components/controls/ThirdPartyUserSelect/ThirdPartyUserSelect';
import { VALIDATION_MESSAGES } from '../validation/utils';
import { constants } from 'constants/env-config';
import { useCreationViewContext } from '../LegalEntityCreationView/context/creation-view-context';
import useFinanceDetailsValidation from '../validation/use-finance-details-validation';
import useLastCompletedTask from '../LegalEntityCreationView/hooks/use-last-completed-task';
import { useLegalEntityFinanceDetails } from '../context/Provider';
import { useReferenceData } from 'api';

export default function LegalEntityFinanceDetails(props: LegalEntityFinanceDetailsProps) {
    const { mode, workItem } = props;
    const { state, getValue, setValue, setValues } = useLegalEntityFinanceDetails();

    const parentFundBusinessUnitIDFilter = constants.PARENT_FUND_BUSINESS_UNIT_ID_FILTER.split(',').map((id) =>
        parseInt(id)
    );

    const {
        state: { loadingStates },
    } = useCreationViewContext();

    const {
        data: {
            values: {
                costCenterNumber,
                billingCostCenterNumber,
                billingProductNumber,
                billingOracleNumber,
                businessUnitOId,
                investranId,
                investranFundFamily,
                investranParentFund,
                productNumber,
                subBusinessUnitOId,
                oracleNumber,
                oracleLineOfBusinessId,
                pushToOracle,
                repaymentGroupId,
                oracleLocationCodeId,
                oracleGSO,
                entityController,
                investranDomain,
                paymentsToWss,
                isAgisRequired,
                billingProjectCode,
                consolidatingStatusInOracleId,
                treasuryLineOfBusinessId,
                primaryLedgerId,
                wssPaymentTypeId,
                gpInterestType,
                gpEntityTier,
                investranGPFundFamily,
            },
            storedValues: { gpInterestType: storedGpInterestType, investranId: storedInvestranId },
            noPendingValues,
            validationErrors: {
                investranParentFund: investranParentFundValidation,
                repaymentGroupId: repaymentGroupIdValidationMessage,
            },
        },
        isEditable,
    } = state;

    const lastSubmitTask = useLastCompletedTask({
        workItem: props.workItem,
        taskType: 'RequestTaskType',
    });

    const lastApproveTask = useLastCompletedTask({
        workItem: props.workItem,
        taskType: 'UpdateRequestTaskType',
    });

    // logic for field visibility: https://blackstone.jira.com/browse/BXEM-25236
    const isEntityTierVisible = useMemo(
        () =>
            [GPInterestTypeData.AffiliatedLimitedPartner, GPInterestTypeData.GeneralPartner].includes(gpInterestType!),
        [gpInterestType]
    );
    const isGPFundFamilyVisible = useMemo(
        () =>
            [
                GPInterestTypeData.AffiliatedLimitedPartner,
                GPInterestTypeData.GeneralPartner,
                GPInterestTypeData.SideBySide,
            ].includes(gpInterestType!),
        [gpInterestType]
    );
    const isFundFamilyVisible = useMemo(
        () => [GPInterestTypeData.SideBySide, GPInterestTypeData.NAFund].includes(gpInterestType!),
        [gpInterestType]
    );

    const isRepaymentGroupIdError =
        !!repaymentGroupIdValidationMessage && repaymentGroupIdValidationMessage !== VALIDATION_MESSAGES.REQUIRED_FIELD;

    const isBillingCodesMatchInitialValue = useCallback(
        () =>
            costCenterNumber === billingCostCenterNumber &&
            ((isNil(oracleNumber) && isNil(billingOracleNumber)) || oracleNumber?.toString() === billingOracleNumber) &&
            productNumber === billingProductNumber,
        [
            costCenterNumber,
            billingCostCenterNumber,
            oracleNumber,
            billingOracleNumber,
            productNumber,
            billingProductNumber,
        ]
    );

    const [isBillingCodesMatch, setIsBillingCodesMatch] = useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(loadingStates)) {
            setIsBillingCodesMatch(isBillingCodesMatchInitialValue());
        }
    }, [loadingStates, isBillingCodesMatchInitialValue]);

    const isStandaloneRequest = useMemo(
        () => (props.workItem && isNil(props.workItem.customProperties.parentItemId)) || props.mode === 'Create',
        [props.mode, props.workItem]
    );
    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return isStandaloneRequest && props.mode === 'Approval' && !isNil(noPendingValues)
            ? !isEqual(newValue, noPendingValue)
            : undefined;
    };

    const { isSegTrustEntity, isSideBySide, isRealEstate, isGPFundFamilyRequired } = useFinanceDetailsValidation({
        isBillingCodesMatch,
        isEntityTierVisible,
        isGPFundFamilyVisible,
        isFundFamilyVisible,
    });

    const { data } = useReferenceData();

    const {
        ConsolidatingStatusInOracle,
        Investran: Investrans,
        InvestranDomain: InvestranDomains,
        InvestranFundFamily,
        OracleGSO,
        PrimaryLedger,
        OracleLineOfBusiness,
        OracleLocation,
        TreasuryLineOfBusiness,
        WssPaymentType,
        GPInterestType,
        GPEntityTier,
        InvestranGPFundFamily,
        RepaymentGroup,
    } = data;

    const repaymentGroupByBUId = useMemo(
        () => getRepaymentGroupByBUId(noPendingValues?.businessUnitOId, RepaymentGroup),
        [noPendingValues, RepaymentGroup]
    );

    useEffect(() => {
        if (
            RepaymentGroup !== undefined &&
            businessUnitOId &&
            repaymentGroupId &&
            !repaymentGroupByBUId.find((rg) => rg.Key === repaymentGroupId)
        ) {
            setValue('repaymentGroupId', undefined);
        }
    }, [businessUnitOId, repaymentGroupByBUId, repaymentGroupId, setValue, RepaymentGroup]);

    // Handling business unit change at LEI from Real Estate to something else
    useEffect(() => {
        if (
            isEditable &&
            !isRealEstate &&
            storedInvestranId === Investran.Investran &&
            storedGpInterestType === GPInterestTypeData.SideBySide
        ) {
            setValue('investranFundFamily', undefined);
        }
    }, [isEditable, storedGpInterestType, storedInvestranId, isRealEstate]);

    const investranFundFamilySource = useMemo(() => {
        if (isSideBySide && InvestranFundFamily?.length && InvestranGPFundFamily?.length) {
            return intersectionBy(InvestranFundFamily, InvestranGPFundFamily, 'Value');
        } else {
            return InvestranFundFamily;
        }
    }, [InvestranFundFamily, InvestranGPFundFamily, isSideBySide]);

    const getPrimaryLedger = useMemo(() => {
        // https://blackstone.jira.com/browse/BXEM-25068
        // move BX_US_USD_PL to the top on the dropdown list
        // reason: This is the most popular selection as most entities will fall under here
        const bxUsUsdPlIndex = PrimaryLedger?.findIndex((item) => item.Value === 'BX_US_USD_PL');
        if (bxUsUsdPlIndex && PrimaryLedger) {
            const optionsWithBxUsUsdOnTop = [
                ...PrimaryLedger?.slice(bxUsUsdPlIndex, bxUsUsdPlIndex + 1),
                ...PrimaryLedger?.slice(0, bxUsUsdPlIndex),
                ...PrimaryLedger?.slice(bxUsUsdPlIndex + 1),
            ];

            return optionsWithBxUsUsdOnTop;
        } else {
            return PrimaryLedger;
        }
    }, [PrimaryLedger]);

    return (
        <>
            <Form>
                <Debugger inputState={state} />
                <Expander
                    header='Finance Details'
                    content={
                        <StackPanel itemGap={SPACING.SM}>
                            <DoubleColumnLayout>
                                <StackPanel itemGap={SPACING.SM}>
                                    <TextField
                                        label='Oracle Number'
                                        labelToolTip='Please contact Blackstone Data Governance for the next available oracle number'
                                        isEditable={false}
                                        value={oracleNumber?.toString()}
                                        isPendingChange={isPendingChange(oracleNumber, noPendingValues?.oracleNumber)}
                                    />
                                    <TextField
                                        label='Product Number'
                                        labelToolTip='Default 4-digit product'
                                        {...getValue('productNumber')}
                                        maxLength={4}
                                        isRequired={pushToOracle}
                                        isPendingChange={isPendingChange(productNumber, noPendingValues?.productNumber)}
                                        onValueChanged={(newValue) => {
                                            setValue('productNumber', newValue);
                                            if (isBillingCodesMatch) {
                                                setValue('billingProductNumber', newValue);
                                            }
                                        }}
                                    />
                                    <TextField
                                        label='Cost Center Number'
                                        labelToolTip='Default 5-digit cost center'
                                        {...getValue('costCenterNumber')}
                                        maxLength={5}
                                        isRequired={pushToOracle}
                                        isPendingChange={isPendingChange(
                                            costCenterNumber,
                                            noPendingValues?.costCenterNumber
                                        )}
                                        onValueChanged={(newValue) => {
                                            setValue('costCenterNumber', newValue);
                                            if (isBillingCodesMatch) {
                                                setValue('billingCostCenterNumber', newValue);
                                            }
                                        }}
                                    />
                                    {isEditable && (
                                        <BooleanField
                                            label='Billing Codes Match Product Codes'
                                            labelToolTip={{
                                                component:
                                                    'Select Yes if the oracle, product, and cost center numbers are the same as the billing numbers.',
                                            }}
                                            value={isBillingCodesMatch}
                                            isEditable={pushToOracle}
                                            onValueChanged={(newValue) => {
                                                setIsBillingCodesMatch(newValue ?? false);
                                                setValues({
                                                    ...(newValue
                                                        ? {
                                                              billingOracleNumber: oracleNumber?.toString(),
                                                              billingProductNumber: productNumber,
                                                              billingCostCenterNumber: costCenterNumber,
                                                          }
                                                        : {
                                                              billingOracleNumber: undefined,
                                                              billingProductNumber: undefined,
                                                              billingCostCenterNumber: undefined,
                                                          }),
                                                });
                                            }}
                                        />
                                    )}
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <>
                                        <TextField
                                            label='Billing Oracle Number'
                                            labelToolTip='Billing Oracle Number that is different from Oracle Number'
                                            {...getValue('billingOracleNumber')}
                                            isPendingChange={isPendingChange(
                                                billingOracleNumber,
                                                noPendingValues?.billingOracleNumber
                                            )}
                                            value={getBillingValue(
                                                'Oracle Number',
                                                oracleNumber,
                                                billingOracleNumber,
                                                isEditable
                                            )}
                                            isReadOnly={isBillingCodesMatch}
                                            canUndo={!isBillingCodesMatch}
                                        />
                                        <TextField
                                            label='Billing Product Number'
                                            labelToolTip='Billing Product Number that is different from Product Number'
                                            {...getValue('billingProductNumber')}
                                            isPendingChange={isPendingChange(
                                                billingProductNumber,
                                                noPendingValues?.billingProductNumber
                                            )}
                                            value={getBillingValue(
                                                'Product Number',
                                                productNumber,
                                                billingProductNumber,
                                                isEditable
                                            )}
                                            maxLength={4}
                                            isReadOnly={isBillingCodesMatch}
                                            isRequired
                                            canUndo={!isBillingCodesMatch}
                                        />
                                        <TextField
                                            label='Billing Cost Center Number'
                                            labelToolTip='Billing Cost Center Number that is different from Cost Center Number'
                                            {...getValue('billingCostCenterNumber')}
                                            isPendingChange={isPendingChange(
                                                billingCostCenterNumber,
                                                noPendingValues?.billingCostCenterNumber
                                            )}
                                            value={getBillingValue(
                                                'Cost Number',
                                                costCenterNumber,
                                                billingCostCenterNumber,
                                                isEditable
                                            )}
                                            maxLength={5}
                                            isRequired
                                            isReadOnly={isBillingCodesMatch}
                                            canUndo={!isBillingCodesMatch}
                                        />
                                    </>
                                </StackPanel>
                            </DoubleColumnLayout>
                            <DoubleColumnLayout>
                                <StackPanel itemGap={SPACING.SM}>
                                    <Select
                                        label='Oracle Line of Business'
                                        labelToolTip={{ component: 'Business unit as appears in Oracle' }}
                                        itemsSource={OracleLineOfBusiness}
                                        isRequired={pushToOracle}
                                        {...getValue('oracleLineOfBusinessId')}
                                        isPendingChange={isPendingChange(
                                            oracleLineOfBusinessId,
                                            noPendingValues?.oracleLineOfBusinessId
                                        )}
                                    />
                                    <Select
                                        label='Oracle Location'
                                        labelToolTip={{ component: 'Select the legal entity oracle location' }}
                                        itemsSource={OracleLocation}
                                        valuePath='ShortValue'
                                        isRequired={pushToOracle}
                                        maxDropDownWidth={700}
                                        {...getValue('oracleLocationCodeId')}
                                        isPendingChange={isPendingChange(
                                            oracleLocationCodeId,
                                            noPendingValues?.oracleLocationCodeId
                                        )}
                                    />
                                    <BooleanField
                                        label='Push to Oracle'
                                        labelToolTip={{
                                            component: 'Indicates whether the data on this page will be sent to Oracle',
                                        }}
                                        {...getValue('pushToOracle')}
                                        onValueChanged={(newValue) => {
                                            setValue('pushToOracle', newValue);
                                            if (newValue === false) {
                                                setIsBillingCodesMatch(false);
                                            }
                                        }}
                                        isPendingChange={isPendingChange(pushToOracle, noPendingValues?.pushToOracle)}
                                    />
                                    {businessUnitOId === KeyValueData.CreditGSO && (
                                        <Select
                                            label='Oracle GSO Legal Entity Family'
                                            itemsSource={OracleGSO}
                                            isRequired={pushToOracle}
                                            {...getValue('oracleGSO')}
                                            isPendingChange={isPendingChange(oracleGSO, noPendingValues?.oracleGSO)}
                                        />
                                    )}
                                    <ThirdPartyUserSelect
                                        label='Entity Controller'
                                        labelToolTip="AVP+ Finance Controller responsible for reconciling and attesting to the entity's balances"
                                        isRequired={pushToOracle}
                                        {...getValue('entityController')}
                                        isReadOnly={!isEditable}
                                        isPendingChange={isPendingChange(
                                            entityController,
                                            noPendingValues?.entityController
                                        )}
                                    />
                                    <BooleanField
                                        label='Payment to Global Treasury (TMS)'
                                        labelToolTip={{
                                            component: 'Payment method',
                                        }}
                                        {...getValue('paymentsToWss')}
                                        isPendingChange={isPendingChange(paymentsToWss, noPendingValues?.paymentsToWss)}
                                    />
                                    <BooleanField
                                        label='Is AGIS Required'
                                        {...getValue('isAgisRequired')}
                                        isPendingChange={isPendingChange(
                                            isAgisRequired,
                                            noPendingValues?.isAgisRequired
                                        )}
                                    />
                                    <Select
                                        label='Investran'
                                        labelToolTip={{
                                            component: 'Select whether the entity should be created in Investran',
                                        }}
                                        itemsSource={Investrans}
                                        isRequired
                                        {...getValue('investranId')}
                                        isPendingChange={isPendingChange(investranId, noPendingValues?.investranId)}
                                        onValueChanged={(newValue) => {
                                            setValues({
                                                investranId: newValue,
                                                ...(newValue === Investran.NonInvestran
                                                    ? {
                                                          investranParentFund: undefined,
                                                          investranFundFamily: undefined,
                                                          investranGPFundFamily: undefined,
                                                          investranDomain: undefined,
                                                          gpInterestType: undefined,
                                                          gpEntityTier: undefined,
                                                      }
                                                    : {
                                                          investranDomain: getDefaultInvestranDomain(
                                                              businessUnitOId,
                                                              subBusinessUnitOId
                                                          ),
                                                      }),
                                            });
                                        }}
                                    />
                                    <FormControl className='form'>
                                        <ElasticSearchField
                                            label='Investran Parent Fund'
                                            searchType={EntitySubType.Fund}
                                            selectedEntityOId={investranParentFund}
                                            isReadOnly={!isEditable || investranId === Investran.NonInvestran}
                                            isEnabled={
                                                (!isUndefined(investranFundFamily) ||
                                                    !isUndefined(investranGPFundFamily)) &&
                                                isEditable
                                            }
                                            onEntitySelected={(result) => {
                                                setValues({ investranParentFund: result?.entityOId });
                                            }}
                                            {...((investranFundFamily || investranGPFundFamily) && {
                                                validationError: investranParentFundValidation,
                                            })}
                                            isPendingChange={isPendingChange(
                                                investranParentFund,
                                                noPendingValues?.investranParentFund
                                            )}
                                            restrictTo={{
                                                businessUnitIds: parentFundBusinessUnitIDFilter,
                                            }}
                                        />
                                    </FormControl>
                                    <Select
                                        label='Investran Domain'
                                        itemsSource={InvestranDomains}
                                        isRequired
                                        {...(investranId !== Investran.NonInvestran && {
                                            ...getValue('investranDomain'),
                                        })}
                                        isEditable={isEditable && investranId !== Investran.NonInvestran}
                                        isPendingChange={isPendingChange(
                                            investranDomain,
                                            noPendingValues?.investranDomain
                                        )}
                                    />
                                </StackPanel>
                                <StackPanel itemGap={SPACING.SM}>
                                    <TextField
                                        label='Billing Project Code'
                                        {...getValue('billingProjectCode')}
                                        isPendingChange={isPendingChange(
                                            billingProjectCode,
                                            noPendingValues?.billingProjectCode
                                        )}
                                        maxLength={6}
                                    />
                                    <Select
                                        label='Consolidating Status in Oracle'
                                        labelToolTip={{
                                            component:
                                                'Contact the Consolidation team for the consolidating status of this entity',
                                        }}
                                        itemsSource={ConsolidatingStatusInOracle}
                                        {...getValue('consolidatingStatusInOracleId')}
                                        isPendingChange={isPendingChange(
                                            consolidatingStatusInOracleId,
                                            noPendingValues?.consolidatingStatusInOracleId
                                        )}
                                        isRequired={pushToOracle}
                                    />
                                    <Select
                                        label='Treasury Line Of Business'
                                        labelToolTip={{ component: 'Select whether this entity is a fund' }}
                                        itemsSource={TreasuryLineOfBusiness}
                                        {...getValue('treasuryLineOfBusinessId')}
                                        isPendingChange={isPendingChange(
                                            treasuryLineOfBusinessId,
                                            noPendingValues?.treasuryLineOfBusinessId
                                        )}
                                        isRequired={pushToOracle}
                                    />
                                    <Select
                                        label='Primary Ledger'
                                        labelToolTip={{
                                            component: 'Select the ledger on which this entity should be created',
                                        }}
                                        itemsSource={getPrimaryLedger}
                                        {...getValue('primaryLedgerId')}
                                        isPendingChange={isPendingChange(
                                            primaryLedgerId,
                                            noPendingValues?.primaryLedgerId
                                        )}
                                        isRequired={pushToOracle}
                                    />
                                    <Select
                                        label='Global Treasury Payment Type'
                                        labelToolTip={{
                                            component:
                                                "Select the type of payment that WSS will make for this entity's bank account",
                                        }}
                                        {...getValue('wssPaymentTypeId')}
                                        value={!isSegTrustEntity ? wssPaymentTypeId : null}
                                        isPendingChange={isPendingChange(
                                            wssPaymentTypeId,
                                            noPendingValues?.wssPaymentTypeId
                                        )}
                                        itemsSource={WssPaymentType}
                                        isRequired={!isSegTrustEntity && pushToOracle}
                                        isEditable={isEditable && !isSegTrustEntity}
                                    />
                                    <Select
                                        {...(isRepaymentGroupIdError ? { height: 90 } : {})}
                                        label='Repayment Group'
                                        labelToolTip={{ component: "Select the entity's repayment group" }}
                                        {...getValue('repaymentGroupId')}
                                        isPendingChange={isPendingChange(
                                            repaymentGroupId,
                                            noPendingValues?.repaymentGroupId
                                        )}
                                        itemsSource={repaymentGroupByBUId}
                                        isRequired={pushToOracle && repaymentGroupByBUId.length > 0}
                                        showError={!!isRepaymentGroupIdError}
                                    />
                                    <Select
                                        label='GP Interest Type'
                                        {...getValue('gpInterestType')}
                                        isEditable={isEditable && investranId === Investran.Investran}
                                        itemsSource={GPInterestType?.filter((type) =>
                                            [
                                                GPInterestTypeData.AffiliatedLimitedPartner,
                                                GPInterestTypeData.GeneralPartner,
                                                GPInterestTypeData.SideBySide,
                                                GPInterestTypeData.NAFund,
                                            ].includes(type.Key)
                                        )}
                                        onValueChanged={(newValue) => {
                                            setValues({
                                                gpInterestType: newValue,
                                                investranFundFamily: undefined,
                                                gpEntityTier: undefined,
                                                investranGPFundFamily: undefined,
                                                investranParentFund: undefined,
                                            });
                                        }}
                                        isPendingChange={isPendingChange(
                                            gpInterestType,
                                            noPendingValues?.gpInterestType
                                        )}
                                        isRequired={investranId === Investran.Investran}
                                    />
                                    {isFundFamilyVisible && (
                                        <Select
                                            label='Investran Fund Family'
                                            labelToolTip={{
                                                component:
                                                    'Select appropriate Investran fund family for the legal entity',
                                            }}
                                            itemsSource={investranFundFamilySource}
                                            isRequired
                                            {...getValue('investranFundFamily')}
                                            isPendingChange={isPendingChange(
                                                investranFundFamily,
                                                noPendingValues?.investranFundFamily
                                            )}
                                            isEditable={
                                                isEditable &&
                                                investranId !== Investran.NonInvestran &&
                                                (isRealEstate || (!isRealEstate && !isSideBySide))
                                            }
                                            onValueChanged={(newValue) => {
                                                setValues({
                                                    investranFundFamily: newValue,
                                                    ...(!newValue && {
                                                        investranParentFund: undefined,
                                                        investranGPFundFamily: undefined,
                                                    }),
                                                });
                                                if (isSideBySide && newValue) {
                                                    const selected = InvestranFundFamily?.find(
                                                        (selected) => selected.Key === newValue
                                                    );
                                                    const gpFundFamily = InvestranGPFundFamily?.find(
                                                        (item) => item.Value === selected?.Value
                                                    );
                                                    setValue('investranGPFundFamily', gpFundFamily?.Key);
                                                }
                                            }}
                                        />
                                    )}
                                    {isGPFundFamilyVisible && (
                                        <Select
                                            label='GP Fund Family'
                                            labelToolTip={{
                                                component: isSideBySide
                                                    ? 'When GP interest type = Side-by-Side, Fund Family = GP Fund Family'
                                                    : null,
                                            }}
                                            {...getValue('investranGPFundFamily')}
                                            itemsSource={InvestranGPFundFamily}
                                            isPendingChange={isPendingChange(
                                                investranGPFundFamily,
                                                noPendingValues?.investranGPFundFamily
                                            )}
                                            isEditable={
                                                isEditable &&
                                                investranId !== Investran.NonInvestran &&
                                                isGPFundFamilyRequired
                                            }
                                            isRequired={isGPFundFamilyRequired}
                                            onValueChanged={(newValue) => {
                                                setValues({
                                                    investranGPFundFamily: newValue,
                                                    ...(!newValue && {
                                                        investranParentFund: undefined,
                                                        investranFundFamily: undefined,
                                                    }),
                                                });
                                            }}
                                        />
                                    )}
                                    {isEntityTierVisible && (
                                        <Select
                                            label='Entity Tier'
                                            {...getValue('gpEntityTier')}
                                            itemsSource={GPEntityTier?.filter((type) =>
                                                [
                                                    GPEntityTierData.T1,
                                                    GPEntityTierData.T2,
                                                    GPEntityTierData.SPV,
                                                ].includes(type.Key)
                                            )}
                                            isPendingChange={isPendingChange(
                                                gpEntityTier,
                                                noPendingValues?.gpEntityTier
                                            )}
                                            isRequired
                                        />
                                    )}
                                </StackPanel>
                            </DoubleColumnLayout>
                        </StackPanel>
                    }
                />
                {props.mode !== 'Create' && <RequestNotes workItem={props.workItem} mode={props.mode} />}
                <RejectReason mode={mode} taskType='UpdateRequestTaskType' tasks={workItem?.tasks} />
                <ContentBox>
                    <PageUpdateInfo label='Last Submitted by' lastCompletedTask={lastSubmitTask} />
                    <PageUpdateInfo label='Last Approved by' lastCompletedTask={lastApproveTask} />
                </ContentBox>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel {...props} />
                </Footer>
            )}
        </>
    );
}
