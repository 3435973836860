import { LegalEntityDissolutionData } from './LegalEntityDissolution';
import { NewStructureType } from '../shared/refData/NewStructureType';

export interface LegalEntityDTO {
    entity: Entity;
    removedEntityHierarchies?: {
        entityHierarchyOId: number;
        serviceProviderDetailIds?: number[];
        signatoryDetailIds?: number[];
    }[];
    removedTaxRegistrations?: number[];
    removedDocuments?: number[];
    removedAddresses?: number[];
    removedTaxCodes?: number[];
    removedAssociatedDeals?: number[];
    entityHierarchies?: EntityHierarchy[];
    madeInNewSystems: boolean;
    dissolutionData?: LegalEntityDissolutionData;
}

export enum AttributeType {
    oracleNumber = 154,
    productNumber = 156,
    costCenterNumber = 157,
    oracleLineOfBusinessId = 159,
    investranId = 160,
    primaryLedgerId = 163,
    treasuryLineOfBusinessId = 166,
    isGIINRequired = 515,
    billingOracleNumber = 516,
    billingProductNumber = 517,
    billingCostCenterNumber = 518,
    investranFundFamily = 370,
    oracleGSO = 524,
    investranParentFund = 508,
    pushLegalEntityToInvestran = 497,
    investranGPFundFamily = 509,
    isGPFund = 503,
    gpInterestType = 504,
    gpReportingType = 505,
    gpEntityTier = 506,
    taxPercentage = 502,
    sbsLegalEntity = 495,
    doubleEntryTransactionProcessing = 498,
    fiscalPeriodEnd = 520,
    investranLegalEntityType = 500,
    investranCurrency = 511,
    investranDomain = 597,
    paymentsToWss = 161,
    isAgisRequired = 521,
    pushToOracle = 522,
    consolidatingStatusInOracleId = 162,
    repaymentGroupId = 530,
    wssPaymentTypeId = 372,
    billingProjectCode = 519,
    oracleLocationCodeId = 525,
    isBlueprintEntity = 358,
    hedgehogID = 158,
    isOrderChop = 704,
    isOrderSeal = 705,
    entityController = 708,
    remarks = 706,
    financialYearEndDate = 703,
    isEntityOwnedByBlackstone = 711,
    bxOwnershipPercentage = 712,
    canBlackstoneControlOwnershipTransfer = 713,
    canBlackstoneEffectuateReorganization = 714,
    corporateTransparencyStatus = 715,
    reason = 716,
    reviewDate = 717,
    CTAReviewReason = 718,
    CTAReviewer = 719,
}

interface Attribute {
    objectAttributeInstanceId: number;
    objectAttributeId: AttributeType;
}
export interface AttributeNumeric extends Attribute {
    value?: number;
}
export interface AttributeDate extends Attribute {
    value?: Date;
}
export interface AttributeBoolean extends Attribute {
    value?: boolean;
}
export interface AttributeString extends Attribute {
    value?: string;
}
export interface AttributeRelationships extends Attribute {
    relatedObjectId?: number;
}
export interface AttributePicklist extends Attribute {
    picklistItemId?: number;
}
export interface AttributeSystempicklists extends Attribute {
    systemPicklistItemId: number;
}

export interface Entity {
    entityOId?: number;
    entityTypeId: number;
    createdUserOid?: number;
    createdDateUtc?: string;
    primaryName?: string;
    shortName?: string;
    primaryNameStartDate?: string;
    entityStatusId: number;
    domainStageID?: string;
    entityGuid?: string;
    ownerSystemId: number;
    legalEntity: LegalEntity;
    entityToSubtypes?: EntityToSubtype[];
    addresses?: Address[];
    taxCodes?: TaxCode[];
    taxRegistrations?: TaxRegistration[];
    entityNames?: EntityNames[];
    dealCountries?: string[];
    latestDomesticTaxRegistrations?: TaxRegistration[];
    latestDomesticTaxRegistrationId?: number;
    objectAttributeNumerics?: AttributeNumeric[];
    objectAttributeDates?: AttributeDate[];
    objectAttributeBooleans?: AttributeBoolean[];
    objectAttributeStrings?: AttributeString[];
    objectAttributeRelationships?: AttributeRelationships[];
    objectAttributePicklists?: AttributePicklist[];
    objectAttributeSystemPicklists?: AttributeSystempicklists[];
}

export interface Address {
    addressId?: number;
    objectId?: number;
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
    city?: string;
    state?: string;
    stateId?: number;
    countryId?: number;
    postalCode?: string;
    addressTypeId?: number;
    countyId?: number;
    cloneMappingId?: string;
    isDeleted?: boolean;
}

export interface TaxCode {
    taxCodeId?: number;
    entityOId?: number;
    taxCode?: string;
    isDeleted?: boolean;
    taxCodeTypeId?: number;
    taxCountry?: number;
    taxCodeType?: {
        countryId?: number;
    };
}

export interface TaxRegistration {
    taxRegistrationId: number;
    taxEntityOid: number;
    formationCountryId?: number;
    formationStateId?: number;
    formationNumber?: string;
    formationDate?: Date;
    registeredAgentId?: number;
    dissolutionDate?: Date;
    cloneMappingId: string;
    isDomestic: boolean;
}

export interface EntityNames {
    entityNameID?: number;
    entityID?: number;
    entityNameTypeID?: number;
    entityNameValue?: string;
    startDate?: Date;
    endDate?: Date;
    deleteDT?: Date;
}

export interface EntityHierarchy {
    entityHierarchyOId?: number;
    parentEntityOId?: number;
    relatedEntityName?: string;
    dataOwnerId?: number;
    childEntityOId: number;
    entityHierarchyTypeId: number;
    serviceProviderDetails: Array<EntityHierarchyServiceProviderDetail>;
    signatoryDetails?: EntityHierarchySignatoryDetail[];
    taxOwnershipDetail?: EntityHierarchyTaxDetail;
}

export interface LegalEntity {
    taxEntityOId?: number;
    taxEntityTypeId?: number;
    newStructureTypeId?: NewStructureType;
    legalTypeID: number;
    businessUnitOId?: number;
    subBusinessUnitOId?: number;
    administeredBy: number;
    businessDriver: number;
    fatcaentityTypeId?: number;
    crsClassificationId?: number;
    crsSubClassification?: number;
    crsSubClassificationDetailId?: number;
    usTaxClassificationTypeId?: number;
    usStateTaxClassificationTypeId?: number;
    isCtbElectionApproved?: boolean;
    tax8832EffectiveDate?: Date;
    isDisregardedEntity?: boolean;
    chapterThreeStatusId?: number;
    legalStatusId?: number;
}

export interface EntityToSubtype {
    entityOId?: number;
    entitySubtypeId: number;
}

export interface EntityHierarchyServiceProviderDetail {
    serviceProviderDetailId?: number;
    entityHierarchyOId?: number;
    serviceProviderTypeId?: number;
    serviceProviderContactEmailId?: number;
}

export interface EntityHierarchySignatoryDetail {
    signatoryDetailId: number;
    appointmentDate?: Date;
    resignationDate?: Date;
    signatoryBlockTitleId: number;
}

export interface EntityHierarchyTaxDetail {
    economicOwnership?: string;
    interestTypeId?: number;
    reportingTypeId?: number;
    interestUnitTypeId?: number;
    interestClassTypeId?: number;
    numberOfUnits?: string;
    votingRight?: boolean;
    shareClass?: number;
    nominalValue?: string;
    currency?: number;
    entityHierarchyOId?: number;
}
