import React, { useEffect } from 'react';
import { StackPanel, TextBlock, ToolBarButton } from '@bxgrandcentral/controls';

import { BooleanField } from 'components';
import { EmailPreferenceCategory } from 'models/UserProfile/EmailPreferenceCategory';
import { EmailPreferences } from 'models/UserProfile/EmailPreferences';
import { IUserProfileService } from 'services/interfaces/IUserProfileService';
import { SPACING } from 'Utilities/Layout';
import { ServiceLocator } from '@bxgrandcentral/shell';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { Component, Content, Header, SubItem } from './Common';

interface Props {
    category: EmailPreferenceCategory;
    title: string;
}

export default function EmailSubSettings({ category, title }: Props) {
    const userService = ServiceLocator.container.resolve(IUserProfileService);
    const [settings, setSettings] = React.useState<EmailPreferencesResult>(EmailPreferencesResult.Fail());
    const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
    const [isExpandedVisual, setIsExpandedVisual] = React.useState<boolean>(true);

    const { accentColor } = useColors();

    useEffect(() => {
        userService
            .LoadEmailPreferences(category)
            .then((result) => {
                setSettings(EmailPreferencesResult.Success(result));
            })
            .catch((e) => {
                console.log('Error: ' + e.toString());
            });
    }, [userService]);

    useEffect(() => {
        const hasTurnedOnSetting = !!Object.entries(settings.EmailPreferences).find((preference) => preference[1]);
        setIsExpanded(hasTurnedOnSetting);
        setIsExpandedVisual(hasTurnedOnSetting);
    }, [settings]);

    return (
        <Component>
            <Header>
                <ToolBarButton
                    foregroundColor={accentColor}
                    fontSize={18}
                    icon={isExpandedVisual ? 'CalculatorSubtract' : 'Add'}
                    onClick={() => {
                        setIsExpandedVisual(!isExpandedVisual);
                    }}
                />
                <TextBlock fontSize={18} text={title} margin={`${SPACING.XXS}px 0 0 0`} />
                <BooleanField
                    trueValueLabel=''
                    falseValueLabel=''
                    variant='dashboard'
                    backgroundColor='none'
                    value={isExpanded}
                    canResetValue={false}
                    canUndo={false}
                    onValueChanged={async (newValue = false) => {
                        const newSettings = { ...settings };
                        newSettings.EmailPreferences.Approved = newValue;
                        newSettings.EmailPreferences.PendingApprove = newValue;
                        newSettings.EmailPreferences.PendingSubmit = newValue;
                        newSettings.EmailPreferences.Rejected = newValue;
                        await userService.SaveEmailPreferences(category, newSettings.EmailPreferences);
                        setIsExpanded(newValue);
                        setIsExpandedVisual(newValue);
                        setSettings(newSettings);
                    }}
                />
            </Header>
            <StackPanel styleOverrides={{ display: isExpandedVisual ? 'flex' : 'none' }}>
                <Content>
                    <StackPanel itemGap={SPACING.XXS}>
                        {BooleanFieldFor('Pending to Submit', 'PendingSubmit')}
                        {BooleanFieldFor('Pending to Approve', 'PendingApprove')}
                        {BooleanFieldFor('Submission Rejected', 'Rejected')}
                        {BooleanFieldFor('Submission Approved', 'Approved')}
                    </StackPanel>
                </Content>
            </StackPanel>
        </Component>
    );

    function BooleanFieldFor(title: string, propName: keyof EmailPreferences) {
        return (
            <SubItem>
                <TextBlock text={title} margin={`${SPACING.XS}px 0 0 0`} />
                <BooleanField
                    trueValueLabel=''
                    falseValueLabel=''
                    variant='dashboard'
                    backgroundColor='none'
                    value={settings.EmailPreferences[propName]}
                    canResetValue={false}
                    canUndo={false}
                    padding={`${SPACING.XXS}px ${SPACING.XXS}px`}
                    width='60px'
                    height={40}
                    onValueChanged={async (newValue = false) => {
                        const newSettings = { ...settings };
                        newSettings.EmailPreferences[propName] = newValue;
                        await userService.SaveEmailPreferences(category, newSettings.EmailPreferences);
                        setSettings(newSettings);
                    }}
                />
            </SubItem>
        );
    }
}

class EmailPreferencesResult {
    public EmailPreferences: EmailPreferences = new EmailPreferences();
    public IsInitialized: boolean = false;
    public IsSuccess: boolean = true;
    constructor(emailPreferences: EmailPreferences, isInitialized: boolean, isSuccess: boolean) {
        this.EmailPreferences = emailPreferences;
        this.IsInitialized = isInitialized;
        this.IsSuccess = isSuccess;
    }

    static Default() {
        return new EmailPreferencesResult(new EmailPreferences(), false, true);
    }

    static Success(preferences: EmailPreferences) {
        if (preferences === undefined) {
            preferences = new EmailPreferences();
        }
        return new EmailPreferencesResult(preferences, true, true);
    }

    static Fail() {
        return new EmailPreferencesResult(new EmailPreferences(), false, false);
    }
}
