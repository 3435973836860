import { LegalEntityRequest, LogError } from '../../models';
import { ReferenceData as NewReferenceData, ReferenceDataId } from '../../api/models/reference-data';
import { ReferenceData, ReferencedDataTypeId } from '../../models/LegalEntityRequest/ReferenceData';

import ApiService from '../ApiService';
import { CanDissolveEntityResponse } from 'modules/LegalEntityCreation/LegalEntityDissolution/LegalEntityDissolutionTreasury/model';
import { ILegalEntityService } from '../interfaces/ILegalEntityService';
import { LegalEntityDTO } from '../../models';
import { LegalEntityDissolutionData } from 'models/LegalEntityRequest/LegalEntityDissolution';
import { LegalEntityStatus } from 'models/LegalEntityRequest/LegalEntityStatus';
import { RecursivePartial } from '../../Utilities/ReflectionUtil';
import { WorkItemState } from 'modules/RequestsDashboard/models';
import { constants } from '../../constants/env-config';
import { entitySections } from '../../modules/LegalEntityCreation/LegalEntityCreationView/EntityToSectionMapper';
import { getSectionUrlForPatch } from 'services/utils';
import { isNil } from 'lodash';

export class LegalEntityService implements ILegalEntityService {
    public logInfo: LogError = {
        service: 'Legal Entity Request Service',
        module: 'Global',
    };

    public baseURL: string = constants.ENTITY_MASTER_API;

    public async getReferenceData(ids: Array<ReferenceDataId>): Promise<NewReferenceData> {
        this.logInfo.method = this.getReferenceData.name;
        let encodedIds = encodeURIComponent(Array.from(new Set(ids)).join(','));
        return ApiService.Get(`${this.baseURL}ref-data/${encodedIds}`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }

    // Get all pages references
    public GetAllRefData(getRefIds: ReferencedDataTypeId[]): Promise<ReferenceData> {
        this.logInfo.method = this.GetAllRefData.name;
        let getRefIdsEncoded = encodeURIComponent(Array.from(new Set(getRefIds)).join(','));
        return ApiService.Get(`${this.baseURL}ref-data/${getRefIdsEncoded}`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }

    public GetUserBusinessUnits(): Promise<ReferenceData> {
        this.logInfo.method = this.GetAllRefData.name;
        return ApiService.Get(`${this.baseURL}ref-data/user-business-units`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }

    public GetUserSubBusinessUnits(): Promise<ReferenceData> {
        this.logInfo.method = this.GetAllRefData.name;
        return ApiService.Get(`${this.baseURL}ref-data/user-sub-business-units`, this.logInfo).catch((e) => {
            return Promise.reject(e);
        });
    }

    public async GetLegalEntities(
        useCache: boolean,
        workItemState: WorkItemState,
        fromDate?: string,
        toDate?: string
    ): Promise<LegalEntityRequest[]> {
        this.logInfo.method = this.GetLegalEntities.name;

        const dateRange = `${isNil(fromDate) ? '' : `&startDate=${fromDate}`}${
            isNil(toDate) ? '' : `&endDate=${toDate}`
        }`;

        return ApiService.Get(
            `${this.baseURL}legal-entity/summaries-for-dashboard?useCache=${useCache}&workItemState=${workItemState}${dateRange}`,
            this.logInfo
        ).catch((e) => {
            return Promise.reject(e);
        });
    }

    // Get a legal entity
    public async GetLegalEntity(entityOId: number, includePendingChanges: boolean = true): Promise<LegalEntityDTO> {
        this.logInfo.method = this.GetLegalEntity.name;

        return ApiService.Get(
            `${this.baseURL}legal-entity/${entityOId}?includeChangesPendingApproval=${includePendingChanges}`,
            this.logInfo
        )
            .then((x: LegalEntityDTO) => {
                return x;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    // Create a legal entity
    public CreateLegalEntity(legalEntityDTO: RecursivePartial<LegalEntityDTO>): Promise<LegalEntityDTO> {
        this.logInfo.method = this.CreateLegalEntity.name;
        return ApiService.Post(`${this.baseURL}legal-entity`, this.logInfo, legalEntityDTO, true, false)
            .then((x: LegalEntityDTO) => {
                return x;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    // Update a legal entity
    public UpdateLegalEntity(
        entityOId: number,
        section: keyof entitySections,
        legalEntityDTO: RecursivePartial<LegalEntityDTO>
    ): Promise<LegalEntityDTO> {
        this.logInfo.method = this.UpdateLegalEntity.name;

        const sectionUrl = getSectionUrlForPatch(section);

        return ApiService.Patch(
            `${this.baseURL}legal-entity/${entityOId}/${sectionUrl}`,
            this.logInfo,
            legalEntityDTO,
            true,
            false
        )
            .then((x: LegalEntityDTO) => {
                return x;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public async GetLegalEntityDissolutionData(workflowId: number): Promise<LegalEntityDissolutionData> {
        this.logInfo.method = this.GetLegalEntityDissolutionData.name;

        return ApiService.Get(`${this.baseURL}dissolution/${workflowId}`, this.logInfo)
            .then((response: LegalEntityDissolutionData) => {
                return response;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public async SaveLegalEntityDissolutionData(
        dissolutionData: RecursivePartial<LegalEntityDissolutionData>
    ): Promise<LegalEntityDissolutionData> {
        this.logInfo.method = this.SaveLegalEntityDissolutionData.name;

        return ApiService.Post(`${this.baseURL}dissolution`, this.logInfo, dissolutionData, true, false)
            .then((response: LegalEntityDissolutionData) => {
                return response;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public async UpdateLegalEntityDissolutionData(
        workflowId: number,
        dissolutionData: RecursivePartial<LegalEntityDissolutionData>,
        section: keyof entitySections
    ): Promise<LegalEntityDissolutionData> {
        this.logInfo.method = this.UpdateLegalEntityDissolutionData.name;

        return ApiService.Patch(
            `${this.baseURL}dissolution/${workflowId}/${section}`,
            this.logInfo,
            dissolutionData,
            true,
            false
        )
            .then((response: LegalEntityDissolutionData) => {
                return response;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public async GetCanDissolveEntity(entityOId: number): Promise<CanDissolveEntityResponse> {
        this.logInfo.method = this.GetCanDissolveEntity.name;

        return ApiService.Get(`${this.baseURL}dissolution/can-dissolve-entity/${entityOId}`, this.logInfo)
            .then((response: CanDissolveEntityResponse) => {
                return response;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    public async GetEntiyStatuses(entityOIds: number[]): Promise<LegalEntityStatus[]> {
        this.logInfo.method = this.GetEntiyStatuses.name;
        return ApiService.Post(`${this.baseURL}legal-entity/statuses`, this.logInfo, entityOIds, true, false)
            .then((response: LegalEntityStatus[]) => {
                return response;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }
}
