import ApprovalStepRenderer from '../stepRenderers/ApprovalStepRenderer';
import CorporateTransparencyStepRenderer from '../stepRenderers/CorporateTransparencyStepRenderer';
import DefaultStepRenderer from '../stepRenderers/DefaultStepRenderer';
import DissolutionApprovalStepRenderer from '../stepRenderers/DissolutionApprovalStepRenderer';
import DissolutionBDGStepRenderer from '../stepRenderers/DissolutionBdgStepRenderer';
import DissolutionDetailsStepRenderer from '../stepRenderers/DissolutionDetailsStepRenderer';
import DissolutionFinanceStepRenderer from '../stepRenderers/DissolutionFinanceStepRenderer';
import DissolutionLegalStepRenderer from '../stepRenderers/DissolutionLegalStepRenderer';
import DissolutionLitigationStepRenderer from '../stepRenderers/DissolutionLitigationStepRenderer';
import DissolutionTaxStepRenderer from '../stepRenderers/DissolutionTaxStepRenderer';
import DissolutionTreasuryStepRenderer from '../stepRenderers/DissolutionTreasuryStepRenderer';
import DocumentPreparationStepRenderer from '../stepRenderers/DocumentPreparationStepRenderer';
import DomesticRegistrationStepRenderer from '../stepRenderers/DomesticRegistrationStepRenderer';
import FinanceDetailsStepRenderer from '../stepRenderers/FinanceDetailsStepRenderer';
import ForeignRegistrationStepRenderer from '../stepRenderers/ForeignRegistrationStepRenderer';
import InformationStepRenderer from '../stepRenderers/InformationStepRenderer';
import OwnershipInformationStepRenderer from '../stepRenderers/OwnershipInformationStepRenderer';
import RequestStepRenderer from '../stepRenderers/RequestStepRenderer';
import { RequestStepsView } from '../RequestStepsView';
import TaxClassificationStepRenderer from '../stepRenderers/TaxClassificationStepRenderer';
import { useCreationViewContext } from '../context/creation-view-context';

export default function useShowForm() {
    const {
        state: { selectedPanel },
    } = useCreationViewContext();

    const showPage = () => {
        switch (selectedPanel?.requestStepView) {
            case RequestStepsView.Legal_Entity_Information:
                return <InformationStepRenderer />;
            case RequestStepsView.Request:
                return <RequestStepRenderer />;
            case RequestStepsView.Document_Preparation:
                return <DocumentPreparationStepRenderer />;
            case RequestStepsView.Domestic_Registration:
                return <DomesticRegistrationStepRenderer />;
            case RequestStepsView.Corporate_Transparency:
                return <CorporateTransparencyStepRenderer />;
            case RequestStepsView.Approval:
                return <ApprovalStepRenderer />;
            case RequestStepsView.Foreign_Registration:
                return <ForeignRegistrationStepRenderer />;
            case RequestStepsView.Tax_Classification:
                return <TaxClassificationStepRenderer />;
            case RequestStepsView.Finance_Details:
                return <FinanceDetailsStepRenderer />;
            case RequestStepsView.Ownership_Information:
                return <OwnershipInformationStepRenderer />;
            case RequestStepsView.Dissolution_Details:
                return <DissolutionDetailsStepRenderer />;
            case RequestStepsView.Dissolution_Approval:
                return <DissolutionApprovalStepRenderer />;
            case RequestStepsView.Dissolution_Litigation:
                return <DissolutionLitigationStepRenderer />;
            case RequestStepsView.Dissolution_Tax:
                return <DissolutionTaxStepRenderer />;
            case RequestStepsView.Dissolution_Finance:
                return <DissolutionFinanceStepRenderer />;
            case RequestStepsView.Dissolution_Legal:
                return <DissolutionLegalStepRenderer />;
            case RequestStepsView.Dissolution_Treasury:
                return <DissolutionTreasuryStepRenderer />;
            case RequestStepsView.Dissolution_BDG:
                return <DissolutionBDGStepRenderer />;
            default:
                return <DefaultStepRenderer />;
        }
    };

    return showPage;
}
