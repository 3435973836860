import {
    AdministeredBy as AdministeredByModel,
    AdministratorHKAPAC as AdministratorHKAPACByModel,
    BusinessUnit as BusinessUnitByModel,
    KeyValueData,
    LawFirm as LawFirmByModel,
} from '../../../../../models';
import { AdministeredByTooltip, StructureTypeTooltip } from 'modules/LegalEntityCreation/Shared/Tooltips';
import { BooleanField, DateTimeField, ElasticSearchField, Expander, Select, StackPanel, TextField } from 'components';
import { DoubleColumnLayout, SPACING } from '../../../../../Utilities/Layout';
import { isEmpty, isEqual, isNil } from 'lodash';
import { isExternalAdmin, isHedgeFund, isPortfolioCompany, shouldShowExternalAdminField } from '../../../utils';
import { useEffect, useMemo } from 'react';

import AssociatedDealSelect from 'components/controls/AssociatedDealSelect/AssociatedDealSelect';
import DealJurisdictionCountry from 'modules/LegalEntityCreation/Shared/DealJurisdictionCountry';
import { EntitySubType } from '../../../../../models/LegalEntity/EntitySubType';
import { FormControl } from '../../../../../components/controls/controls.styled';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { convertToLocalDate } from '../../../../../Utilities/date';
import { shouldHaveSubBusinessUnit } from '../../../utils';
import useInformationValidation from 'modules/LegalEntityCreation/validation/use-information-validation';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useReferenceData } from '../../../../../api';

export default function InformationDetails() {
    const {
        state: {
            isEditable,
            data: {
                values: { administeredBy, businessUnitOId, projectEntityOId, isBlueprintEntity, dealCountries },
                changes: { associatedDeal: changedAssociatedDeal },
                storedValues: { associatedDeal: storedAssociatedDeal },
                noPendingValues,
            },
        },
        getValue,
        setValue,
        setValues,
    } = useLegalEntityInformation();

    useInformationValidation();

    const {
        data: {
            BusinessUnit,
            ExternalAdmin,
            LawFirm,
            LegalType,
            LegalStatus,
            Portfolio,
            SubBusinessUnit,
            NewStructureType,
            AdministeredBy,
            AdministratorHKAPAC,
            TaxAuditor,
        },
    } = useReferenceData();

    const subBusinessUnits = useMemo(
        () => SubBusinessUnit?.filter(({ ParentId }) => ParentId === `${businessUnitOId}`),
        [SubBusinessUnit, businessUnitOId]
    );

    const structureTypes = useMemo(
        () =>
            businessUnitOId === KeyValueData.BTO
                ? NewStructureType
                : NewStructureType?.filter(({ Key }) => Key !== KeyValueData.Sleeve),
        [NewStructureType, businessUnitOId]
    );

    const isAdministeredByAsia = administeredBy === AdministeredByModel.Asia;
    const isExternalAdminSelected = isExternalAdmin(administeredBy);
    const isPortfolioCompanySelected = isPortfolioCompany(administeredBy);
    const isExternalAdminDropdownEnabled = isBlueprintEntity || shouldShowExternalAdminField(administeredBy);

    useEffect(() => {
        if (!isBlueprintEntity) {
            setValue('associatedDeal', undefined);
        }
    }, [isBlueprintEntity, setValue]);

    const onBusinessUnitIdChanged = (value?: number) => {
        setValues({
            businessUnitOId: value,
            subBusinessUnitOId: undefined,
            projectEntityOId: undefined,
            hedgehogID: undefined,
            shortName: undefined,
        });

        if (isAdministeredByAsia && value === BusinessUnitByModel.RealEstate) {
            setValue('externalAdminOId', LawFirmByModel.RevantageGlobalServicesAsia);
        }
    };

    const onAdministeredByChanged = (value?: number) => {
        const isAdministeredByAsia = value === AdministeredByModel.Asia;

        setValues({
            administeredBy: value,
            externalAdminOId:
                isAdministeredByAsia && businessUnitOId === BusinessUnitByModel.RealEstate
                    ? LawFirmByModel.RevantageGlobalServicesAsia
                    : undefined,
            isBlueprintEntity: isAdministeredByAsia,
            administratorHKAPACOId: isAdministeredByAsia ? AdministratorHKAPACByModel.IntertrustGroup : undefined,
            isOrderChop: isAdministeredByAsia ? false : undefined,
            isOrderSeal: isAdministeredByAsia ? false : undefined,
            remarks: undefined,
            financialYearEndDate: undefined,
        });
    };

    const onExternalAdminIdChanged = (value?: number) => {
        setValues({
            externalAdminOId: value,
            externalAdminContact: undefined,
        });
    };

    const onFinancialYearEndDateChanged = (value?: Date | string | undefined) => {
        setValue('financialYearEndDate', convertToLocalDate(value));
    };

    return (
        <Expander
            header='Legal Entity Details'
            requestStep={RequestStepsView.Legal_Entity_Information}
            content={
                <StackPanel itemGap={SPACING.SM}>
                    <TextField
                        label='Legal Name'
                        labelToolTip="Legal entity's full legal name that should be unique"
                        {...getValue('legalName')}
                        isEditable={false}
                    />
                    {isHedgeFund(businessUnitOId) && (
                        <TextField
                            label='Short Name'
                            labelToolTip='Name used for display purposes'
                            {...getValue('shortName')}
                        />
                    )}
                    <DoubleColumnLayout>
                        <StackPanel itemGap={SPACING.SM}>
                            <Select
                                label='Business Unit'
                                labelToolTip={{ component: "Legal entity's business unit that governs the entity" }}
                                itemsSource={BusinessUnit}
                                isRequired
                                {...getValue('businessUnitOId')}
                                onValueChanged={onBusinessUnitIdChanged}
                            />
                            {!isEmpty(subBusinessUnits) && (
                                <Select
                                    label='Sub-Business Unit'
                                    labelToolTip={{
                                        component:
                                            "Legal entity's sub-business unit if BU = Credit, Hedge Fund Solutions, Real Estate, and General Partners Accounting",
                                    }}
                                    itemsSource={subBusinessUnits}
                                    isRequired={shouldHaveSubBusinessUnit(businessUnitOId)}
                                    {...getValue('subBusinessUnitOId')}
                                />
                            )}
                            <FormControl className='form'>
                                <ElasticSearchField
                                    label='Project'
                                    labelToolTip="Apologies for the inconvenience. 'Project' is currently unavailable. If you need to tag a project to the entity, please contact Data Governance for assistance."
                                    isEnabled={false}
                                    searchType={EntitySubType.Project}
                                    restrictTo={{
                                        businessUnitIds: !businessUnitOId ? undefined : [businessUnitOId],
                                    }}
                                    selectedEntityOId={projectEntityOId}
                                    onEntitySelected={(result) => setValue('projectEntityOId', result?.entityOId)}
                                    isReadOnly={!isEditable || !businessUnitOId}
                                    isPendingChange={
                                        !isEditable &&
                                        !isNil(noPendingValues) &&
                                        !isEqual(projectEntityOId, noPendingValues?.projectEntityOId)
                                    }
                                />
                            </FormControl>
                            <BooleanField
                                label='Is Blueprint Entity'
                                labelToolTip={{
                                    component: 'Indicates whether entity exists in Blueprint. Will create if not',
                                }}
                                {...getValue('isBlueprintEntity')}
                                isReadOnly
                                isEnabled={false}
                            />
                            {isBlueprintEntity && (
                                <>
                                    <BooleanField
                                        label='Order chop'
                                        labelToolTip={{
                                            component: 'Has a registered Chop been requested',
                                        }}
                                        {...getValue('isOrderChop')}
                                    />
                                    <BooleanField
                                        label='Order Seal'
                                        labelToolTip={{
                                            component: 'Has a registered Seal been requested',
                                        }}
                                        {...getValue('isOrderSeal')}
                                    />
                                    <TextField
                                        label='Remarks'
                                        labelToolTip='Commentary on Chop or Seal registration and location'
                                        {...getValue('remarks')}
                                    />
                                    <DateTimeField
                                        label='Financial Year End (FYE Date)'
                                        labelToolTip='Ending date of financial year of an entity'
                                        {...getValue('financialYearEndDate')}
                                        onValueChanged={onFinancialYearEndDateChanged}
                                    />
                                </>
                            )}
                            <Select
                                label='Law Firm'
                                labelToolTip={{ component: 'Law Firm representing the legal entity' }}
                                itemsSource={LawFirm}
                                {...getValue('lawFirmEntityOId')}
                                isRequired={!isExternalAdmin(administeredBy) && !isBlueprintEntity}
                            />
                        </StackPanel>
                        <StackPanel itemGap={SPACING.SM}>
                            <Select
                                label='Legal Type'
                                labelToolTip={{ component: 'Business legal structure of the entity' }}
                                itemsSource={LegalType}
                                isRequired
                                {...getValue('legalTypeId')}
                            />
                            <Select
                                label='Structure Type'
                                labelToolTip={{ component: StructureTypeTooltip(), options: { showDuration: 60000 } }}
                                itemsSource={structureTypes}
                                isRequired
                                {...getValue('structureType')}
                            />
                            <Select
                                label='Administered / Managed By'
                                labelToolTip={{ component: AdministeredByTooltip() }}
                                itemsSource={AdministeredBy}
                                {...getValue('administeredBy')}
                                isRequired
                                onValueChanged={onAdministeredByChanged}
                            />
                            {(isExternalAdminDropdownEnabled || isPortfolioCompanySelected) && (
                                <Select
                                    label='Administrator - HK APAC'
                                    itemsSource={AdministratorHKAPAC}
                                    {...getValue('administratorHKAPACOId')}
                                    isReadOnly={isBlueprintEntity}
                                    canUndo={!isBlueprintEntity}
                                />
                            )}
                            {isExternalAdminDropdownEnabled && (
                                <Select
                                    label='External Admin'
                                    labelToolTip={{
                                        component:
                                            'When the entity is managed by an external financial institution and is not a BX Entity',
                                    }}
                                    itemsSource={ExternalAdmin}
                                    {...getValue('externalAdminOId')}
                                    isRequired={isExternalAdminSelected}
                                    onValueChanged={onExternalAdminIdChanged}
                                />
                            )}
                            {isPortfolioCompanySelected && (
                                <Select
                                    label='External Admin'
                                    labelToolTip={{
                                        component:
                                            'When the entity is managed by an external financial institution and is not a BX Entity',
                                    }}
                                    itemsSource={Portfolio}
                                    {...getValue('portfolioId')}
                                />
                            )}
                            {isBlueprintEntity && (
                                <AssociatedDealSelect
                                    label='Associated Deal'
                                    {...getValue('associatedDeal')}
                                    isReadOnly={!isEditable}
                                    storedValue={storedAssociatedDeal}
                                />
                            )}
                            <Select
                                label='Tax Auditor'
                                labelToolTip={{ component: 'Select third party tax auditor' }}
                                itemsSource={TaxAuditor}
                                {...getValue('taxAuditorOId')}
                            />
                            <Select
                                label='Legal Status'
                                labelToolTip={{ component: 'Select the legal status for this legal entity.' }}
                                itemsSource={LegalStatus}
                                {...getValue('legalStatusId')}
                            />
                        </StackPanel>
                    </DoubleColumnLayout>
                    <DealJurisdictionCountry
                        isVisible={isBlueprintEntity}
                        countries={dealCountries}
                        isDealChanged={!!changedAssociatedDeal || changedAssociatedDeal === null}
                    />
                </StackPanel>
            }
        />
    );
}
