import { Expander, StackPanel } from 'components/';
import { TextBlock, ThemeColors } from '@bxgrandcentral/controls';

import { HORIZONTAL_FORM_PADDING } from 'Utilities/Layout';
import { RequestStepsView } from 'modules/LegalEntityCreation/LegalEntityCreationView/RequestStepsView';
import { getDateMMDDYYYY } from 'Utilities/date';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';

export default function AlternateNames() {
    const {
        state: {
            data: {
                values: { entityNames },
            },
        },
    } = useLegalEntityInformation();

    const colors = useColors();

    return (
        <Expander
            header='Alternate Names'
            requestStep={RequestStepsView.Legal_Entity_Information}
            padding={0}
            content={
                <StackPanel padding={HORIZONTAL_FORM_PADDING}>
                    {!entityNames?.length ? (
                        <TextBlock styleName='captionStyle'>This entity has no alternate names.</TextBlock>
                    ) : (
                        <Table colors={colors}>
                            <div className='row header'>
                                <div className='cell'>
                                    <TextBlock styleName='heading4Style'>Alternate Name</TextBlock>
                                </div>
                                <div className='cell'>
                                    <TextBlock styleName='heading4Style'>Effective Date</TextBlock>
                                </div>
                                <div className='cell'>
                                    <TextBlock styleName='heading4Style'>End Date</TextBlock>
                                </div>
                            </div>
                            {entityNames.map(({ entityNameValue, startDate, endDate }) => (
                                <div className='row' key={entityNameValue}>
                                    <div className='cell'>{entityNameValue}</div>
                                    <div className='cell'>{getDateMMDDYYYY(startDate?.toString())}</div>
                                    <div className='cell'>{getDateMMDDYYYY(endDate?.toString())}</div>
                                </div>
                            ))}
                        </Table>
                    )}
                </StackPanel>
            }
            isExpanded={false}
        />
    );
}

export const Table = styled.div<{ colors: ThemeColors }>`
    width: 100%;
    display: table;
    margin: 0 0 0 0;
    height: fit-content;
    font-size: 14px;

    .row {
        display: table-row;
    }

    .row.header {
        color: ${({ colors }) => colors.foregroundColor};
    }

    .cell {
        display: table-cell;
        text-align: center;
    }

    .row.header .cell {
        padding: 8px 0;

        span {
            text-transform: none;
        }
    }

    .row .cell {
        line-height: 1.2;
        padding: 6px 0;
        border-bottom: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};

        &:first-child {
            text-align: left;
            padding-left: 20px;
        }
        &:nth-last-child(-n + 2) {
            width: 150px;
        }
    }

    .row:last-child .cell {
        border: none;
    }
`;
