import React from 'react';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';

type PermissionInfoTableProps = {
    roles?: string[];
    title: string;
};

export default function PermissionInfoTable({ roles = [], title = '' }: PermissionInfoTableProps) {
    const colors = useColors();

    if (!roles.length) {
        return null;
    }

    return (
        <Table colors={colors}>
            <div className='row header'>
                <div className='cell'>{title}</div>
            </div>
            {roles.map((role) => (
                <div className='row' key={role}>
                    <div className='cell'>{role}</div>
                </div>
            ))}
        </Table>
    );
}

export const Table = styled.div<{ colors: ThemeColors }>`
    width: 100%;
    display: table;
    margin: 0 0 0 0;
    height: fit-content;
    font-size: 12px;

    .row {
        display: table-row;
    }

    .row.header {
        color: #ffffff;
        background: #111;
    }

    .cell {
        display: table-cell;
        text-align: center;
        text-transform: uppercase;
    }

    .row .cell {
        line-height: 1.2;
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: ${({ colors }) => `1px solid ${colors.normalControlBorderColor}`};
    }

    .row.header .cell {
        border: none;
    }
`;
