import ApiService from 'services/ApiService';
import { EmailPreferenceCategory } from 'models/UserProfile/EmailPreferenceCategory';
import { EmailPreferences } from 'models/UserProfile/EmailPreferences';
import { CtaEmailPreferences } from 'models/UserProfile/CtaEmailPreferences';
import { CreationEmailPreferences } from 'models/UserProfile/CreationEmailPreferences';
import { IUserProfileService } from 'services/interfaces/IUserProfileService';
import { constants } from '../../constants/env-config';
import { appId } from 'configs/App.config';

export class UserProfileService implements IUserProfileService {
    LoadCreationEmailPreferences(): Promise<CreationEmailPreferences> {
        return ApiService.Get(this.Url(EmailPreferenceCategory.LegalEntityCreation), this.logInfo).catch((e) =>
            Promise.reject(e)
        );
    }

    SaveCreationEmailPreferences(settings: CreationEmailPreferences): Promise<any> {
        let json = JSON.stringify(settings);
        return ApiService.Put(
            this.Url(EmailPreferenceCategory.LegalEntityCreation) + '?settingValue=' + encodeURIComponent(json),
            this.logInfo,
            null
        ).catch((e) => Promise.reject(e));
    }

    LoadCtaEmailPreferences(): Promise<CtaEmailPreferences> {
        return ApiService.Get(this.Url(EmailPreferenceCategory.CtaNotification), this.logInfo).catch((e) =>
            Promise.reject(e)
        );
    }

    SaveCtaEmailPreferences(settings: CtaEmailPreferences): Promise<any> {
        let json = JSON.stringify(settings);
        return ApiService.Put(
            this.Url(EmailPreferenceCategory.CtaNotification) + '?settingValue=' + encodeURIComponent(json),
            this.logInfo,
            null
        ).catch((e) => Promise.reject(e));
    }

    LoadEmailPreferences(category: EmailPreferenceCategory): Promise<EmailPreferences> {
        return ApiService.Get(this.Url(category), this.logInfo).catch((e) => Promise.reject(e));
    }

    SaveEmailPreferences(category: EmailPreferenceCategory, settings: EmailPreferences): Promise<any> {
        if (
            category == EmailPreferenceCategory.CtaNotification ||
            category == EmailPreferenceCategory.LegalEntityCreation
        ) {
            throw new Error('Unsupported category: ' + category);
        }

        let json = JSON.stringify(settings);
        return ApiService.Put(
            this.Url(category) + '?settingValue=' + encodeURIComponent(json),
            this.logInfo,
            null
        ).catch((e) => Promise.reject(e));
    }

    Url(category: EmailPreferenceCategory) {
        const url = `${constants.USERS_API}my/apps/${appId}/features/notifications/settings/${EmailPreferenceCategory[category]}`;

        return url;
    }

    logInfo = {
        service: 'User Profile Service',
        module: 'Settings',
    };
}
