import { useCallback, useEffect, useState } from 'react';

import { KeyValue } from 'models';
import { isEmpty } from 'lodash';
import { splitCamelCaseString } from 'Utilities/string';
import { useReferenceData } from 'api';
import useUserInformation from 'hooks/use-user-information';

export type PermissionsInfo = {
    legalEntityPermissions?: string[];
    businessUnitPermissions?: string[];
    subBusinessUnitPermissions?: string[];
    lawFirmPermissions?: string[];
    externalAdminPermissions?: string[];
    registeredAgentPermissions?: string[];
    otherPermissions?: string[];
    noPermissions?: boolean;
    isLoading?: boolean;
};

const permissionsMap = {
    businessUnit: { id: 'business-unit', title: 'Business Unit' },
    subBusinessUnit: { id: 'sub-business-unit', title: 'Sub Business Unit' },
    lawFirm: { id: 'legalcounsel', title: 'Law Firm' },
    externalAdmin: { id: 'externaladmin', title: 'External Admin' },
    registeredAgent: { id: 'registeredagent', title: 'Registered Agent' },
    legalEntity: { id: 'legal-entity', title: 'Legal Entity' },
};

export default function useUserPermissionInfo() {
    const { data: referenceData, isLoading } = useReferenceData();

    const {
        data: { capabilities },
    } = useUserInformation();

    const [permissions, setPermissions] = useState<PermissionsInfo>({
        legalEntityPermissions: [],
        otherPermissions: [],
    });

    const addPermissionFromRefData = useCallback(
        (existingPermissions: string[] = [], brnValue: string = '', refDataArray: KeyValue[] | undefined) => {
            const result = [...existingPermissions];

            const relatedItem = refDataArray?.find(({ Key }) => Key === parseInt(brnValue));

            if (relatedItem && !existingPermissions.includes(relatedItem.Value)) {
                result.push(relatedItem.Value);
            } else if (brnValue.length && !result.includes(brnValue)) {
                result.push(`id: ${brnValue}`);
            }

            return result.sort();
        },
        []
    );

    const addLegalEntityPermissions = useCallback(
        (existingPermissions: string[] = [], brnValue: string = '', capability: string) => {
            const result = [...existingPermissions];

            const permission = `${splitCamelCaseString(capability)} - ${brnValue}`;

            if (!existingPermissions.includes(permission)) {
                result.push(permission);
            }

            return result.sort();
        },
        []
    );

    useEffect(() => {
        const { BusinessUnit, SubBusinessUnit, LawFirm, ExternalAdmin, RegisteredAgent } = referenceData;
        const permissionsCopy = { ...permissions };

        permissionsCopy.isLoading = isLoading;

        if (!capabilities.length || isEmpty(referenceData)) {
            permissionsCopy.noPermissions = true;
            setPermissions(permissionsCopy);
            return;
        }

        permissionsCopy.noPermissions = !capabilities.length;

        capabilities.forEach(({ capability, brn }) => {
            const [brnId, brnValue] = brn.split(':');

            switch (true) {
                case brnId === permissionsMap.businessUnit.id:
                    permissionsCopy.businessUnitPermissions = addPermissionFromRefData(
                        permissionsCopy.businessUnitPermissions,
                        brnValue,
                        BusinessUnit
                    );
                    break;
                case brnId === permissionsMap.subBusinessUnit.id:
                    permissionsCopy.subBusinessUnitPermissions = addPermissionFromRefData(
                        permissionsCopy.subBusinessUnitPermissions,
                        brnValue,
                        SubBusinessUnit
                    );
                    break;
                case brnId === permissionsMap.lawFirm.id:
                    permissionsCopy.lawFirmPermissions = addPermissionFromRefData(
                        permissionsCopy.lawFirmPermissions,
                        brnValue,
                        LawFirm
                    );
                    break;
                case brnId === permissionsMap.externalAdmin.id:
                    permissionsCopy.externalAdminPermissions = addPermissionFromRefData(
                        permissionsCopy.externalAdminPermissions,
                        brnValue,
                        ExternalAdmin
                    );
                    break;
                case brnId === permissionsMap.registeredAgent.id:
                    permissionsCopy.registeredAgentPermissions = addPermissionFromRefData(
                        permissionsCopy.registeredAgentPermissions,
                        brnValue,
                        RegisteredAgent
                    );
                    break;
                case brnId === permissionsMap.legalEntity.id:
                    permissionsCopy.legalEntityPermissions = addLegalEntityPermissions(
                        permissionsCopy.legalEntityPermissions,
                        brnValue,
                        capability
                    );
                    break;
                default:
                    permissionsCopy.otherPermissions?.push(brnValue || brnId);
            }
        });

        setPermissions(permissionsCopy);
    }, [capabilities, referenceData, isLoading, addLegalEntityPermissions, addPermissionFromRefData]);

    return permissions;
}
