import React, { useEffect } from 'react';
import { StackPanel, TextBlock, ToolBarButton } from '@bxgrandcentral/controls';

import { BooleanField } from 'components';
import { CreationEmailPreferences } from 'models/UserProfile/CreationEmailPreferences';
import { IUserProfileService } from 'services/interfaces/IUserProfileService';
import { SPACING } from 'Utilities/Layout';
import { ServiceLocator } from '@bxgrandcentral/shell';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { Component, Content, Header, SubItem } from './Common';

interface Props {
    title: string;
}

export default function CreationSubSettings({ title }: Props) {
    const userService = ServiceLocator.container.resolve(IUserProfileService);
    const [settings, setSettings] = React.useState<CreationPreferencesResult>(CreationPreferencesResult.Fail());
    const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
    const [isExpandedVisual, setIsExpandedVisual] = React.useState<boolean>(true);

    const { accentColor } = useColors();

    useEffect(() => {
        userService
            .LoadCreationEmailPreferences()
            .then((result) => {
                setSettings(CreationPreferencesResult.Success(result));
            })
            .catch((e) => {
                console.log('Error: ' + e.toString());
            });
    }, [userService]);

    useEffect(() => {
        const hasTurnedOnSetting = !!Object.entries(settings.Preferences).find((preference) => preference[1]);
        setIsExpanded(hasTurnedOnSetting);
        setIsExpandedVisual(hasTurnedOnSetting);
    }, [settings]);

    return (
        <Component>
            <Header>
                <ToolBarButton
                    foregroundColor={accentColor}
                    fontSize={18}
                    icon={isExpandedVisual ? 'CalculatorSubtract' : 'Add'}
                    onClick={() => {
                        setIsExpandedVisual(!isExpandedVisual);
                    }}
                />
                <TextBlock fontSize={18} text={title} margin={`${SPACING.XXS}px 0 0 0`} />
                <BooleanField
                    trueValueLabel=''
                    falseValueLabel=''
                    variant='dashboard'
                    backgroundColor='none'
                    value={isExpanded}
                    canResetValue={false}
                    canUndo={false}
                    onValueChanged={async (newValue = false) => {
                        const newSettings = { ...settings };
                        newSettings.Preferences.ReviewRejected = newValue;
                        newSettings.Preferences.DocumentPreparationPending = newValue;
                        newSettings.Preferences.DocumentReviewPending = newValue;
                        newSettings.Preferences.RegistrationPending = newValue;
                        newSettings.Preferences.ApprovalPending = newValue;
                        newSettings.Preferences.ApprovalRejected = newValue;
                        await userService.SaveCreationEmailPreferences(newSettings.Preferences);
                        setIsExpanded(newValue);
                        setIsExpandedVisual(newValue);
                        setSettings(newSettings);
                    }}
                />
            </Header>
            <StackPanel styleOverrides={{ display: isExpandedVisual ? 'flex' : 'none' }}>
                <Content>
                    <StackPanel itemGap={SPACING.XXS}>
                        {BooleanFieldFor('Review Rejected Request', 'ReviewRejected')}
                        {BooleanFieldFor('Document Preparation Pending', 'DocumentPreparationPending')}
                        {BooleanFieldFor('Document Review Pending', 'DocumentReviewPending')}
                        {BooleanFieldFor('Registration Pending', 'RegistrationPending')}
                        {BooleanFieldFor('Approval Pending', 'ApprovalPending')}
                        {BooleanFieldFor('Approval Rejected', 'ApprovalRejected')}
                    </StackPanel>
                </Content>
            </StackPanel>
        </Component>
    );

    function BooleanFieldFor(title: string, propName: keyof CreationEmailPreferences) {
        return (
            <SubItem>
                <TextBlock text={title} margin={`${SPACING.XS}px 0 0 0`} />
                <BooleanField
                    trueValueLabel=''
                    falseValueLabel=''
                    variant='dashboard'
                    backgroundColor='none'
                    value={settings.Preferences[propName]}
                    canResetValue={false}
                    canUndo={false}
                    padding={`${SPACING.XXS}px ${SPACING.XXS}px`}
                    width='60px'
                    height={40}
                    onValueChanged={async (newValue = false) => {
                        const newSettings = { ...settings };
                        newSettings.Preferences[propName] = newValue;
                        await userService.SaveCreationEmailPreferences(newSettings.Preferences);
                        setSettings(newSettings);
                    }}
                />
            </SubItem>
        );
    }
}

class CreationPreferencesResult {
    public Preferences: CreationEmailPreferences = new CreationEmailPreferences();
    public IsInitialized: boolean = false;
    public IsSuccess: boolean = true;
    constructor(emailPreferences: CreationEmailPreferences, isInitialized: boolean, isSuccess: boolean) {
        this.Preferences = emailPreferences;
        this.IsInitialized = isInitialized;
        this.IsSuccess = isSuccess;
    }

    static Default() {
        return new CreationPreferencesResult(new CreationEmailPreferences(), false, true);
    }

    static Success(preferences: CreationEmailPreferences) {
        if (preferences === undefined) {
            preferences = new CreationEmailPreferences();
        }
        return new CreationPreferencesResult(preferences, true, true);
    }

    static Fail() {
        return new CreationPreferencesResult(new CreationEmailPreferences(), false, false);
    }
}
