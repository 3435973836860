import { ICellRendererParams } from 'ag-grid-community';
import { SPACING } from 'Utilities/Layout';
import { ThemeColors } from '@bxgrandcentral/controls';
import styled from 'styled-components';
import useColors from 'api/hooks/use-theme';
import { useLocation } from 'react-router-dom';
import React from 'react';

interface EntityOIdCellRendererProps extends ICellRendererParams {
    redirectTo: string;
}

export default function ParentEntityOIdCellRenderer({ redirectTo, value, data }: EntityOIdCellRendererProps) {
    const { parentEntity } = data;
    const location = useLocation();
    const colors = useColors();

    function redirect() {
        const updatedPath = location.pathname.replace(/\/\d+\/[^/]+/, `/${parentEntity}/${redirectTo}`);
        window.open(updatedPath, '_blank');
    }

    return (
        <Button id='cell-relatedEntityName' colors={colors} onClick={() => redirect()}>
            {value}
        </Button>
    );
}

const Button = styled.button<{ colors: ThemeColors }>`
    padding: ${SPACING.XXS}px;
    border: none;
    background: none;
    color: ${({ colors }) => colors.accentColor};
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
