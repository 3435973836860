import PermissionInfo from 'components/PermissionInfo/PermissionInfo';
import styled from 'styled-components';

export default function Permissions() {
    return (
        <Wrapper>
            <PermissionInfo settingsMode title='Your Current Permissions' />
        </Wrapper>
    );
}

export const Wrapper = styled.div`
    overflow-y: auto;
    padding: 20px 10px;
    text-align: center;
`;
