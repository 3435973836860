//TODO: move to separate file

export enum EmailPreferenceCategory {
    LegalEntityInformation,
    FinanceDetails,
    TaxInformation,
    OwnershipInformation,
    ForeignRegistration,
    CtaNotification,
    LegalEntityCreation,
}
