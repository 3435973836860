import { FileListPanel, FileUploadBorder } from '../FileUpload/FileUpload.styled';
import { StoredDocument, StoredDocumentModel } from '../FileUpload/models';
import { useCallback, useMemo } from 'react';

import { DocumentType } from '../../../models/shared/refData/NewDocumentType';
import FileListTreeView from '../FileUpload/components/FileListTreeView';
import { SectionName } from 'modules/LegalEntityCreation/models';
import { WaitingIndicator } from '@bxgrandcentral/controls';
import { getValuesOf } from 'Utilities/object';
import useColors from 'api/hooks/use-theme';
import useDocuments from 'api/hooks/use-documents';

type Props = {
    entityId?: number;
    sectionName: SectionName;
    treeViewDocumentTypes: DocumentType[] | undefined;
};

export default function DocumentTreeList({ entityId = 0, sectionName, treeViewDocumentTypes }: Props) {
    const colors = useColors();
    const { data: documents = [], isFetching } = useDocuments(entityId, sectionName);

    const sortDocuments = (documents: StoredDocument[]) => {
        return documents.sort((a, b) => {
            const dateA = a.effectiveDateString ? new Date(a.effectiveDateString) : null;
            const dateB = b.effectiveDateString ? new Date(b.effectiveDateString) : null;

            if (dateA && dateB) {
                return dateB.getTime() - dateA.getTime();
            } else if (dateA) {
                return -1;
            } else if (dateB) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    const getDocumentsForTreeView = useCallback(() => {
        const sortedDocuments = sortDocuments(documents);

        const docs = treeViewDocumentTypes
            ? sortedDocuments.filter(({ coreDocumentTypeId }) => {
                  return getValuesOf(treeViewDocumentTypes)?.some(({ Key }) => Key === coreDocumentTypeId);
              })
            : sortedDocuments;

        return docs.map((document) => {
            return {
                document,
                updates: {},
            } as StoredDocumentModel;
        });
    }, [documents, treeViewDocumentTypes]);

    const documentsForTreeView = useMemo(() => {
        return getDocumentsForTreeView();
    }, [documents]);

    return (
        <FileUploadBorder colors={colors}>
            <FileListPanel colors={colors} style={{ borderBottomWidth: 0 }}>
                {isFetching && (
                    <WaitingIndicator id='spinner' content='Loading documents' isVisible isModalToShell={false} />
                )}
                <FileListTreeView
                    entityOId={entityId}
                    documents={documentsForTreeView}
                    isFetching={isFetching}
                    sectionName={sectionName}
                    onDocumentStateChanged={() => {}}
                />
            </FileListPanel>
        </FileUploadBorder>
    );
}
