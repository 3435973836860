import NoPermissionInfo from '../components/NoPermissionInfo/NoPermissionInfo';
import styled from 'styled-components';
import { useCreationViewContext } from '../context/creation-view-context';
import useIsSupportedEntity from '../hooks/use-is-supported-entity';

export default function DefaultStepRenderer() {
    const {
        state: { sideBarLength },
    } = useCreationViewContext();

    const isSupportedEntity = useIsSupportedEntity();

    if (!isSupportedEntity) {
        return (
            <Wrapper>
                This entity's type is not a Blackstone Legal Entity and is not supported in Request Center.
            </Wrapper>
        );
    }

    return <Wrapper>{sideBarLength ? 'Please select a step from the left menu.' : <NoPermissionInfo />}</Wrapper>;
}

export const Wrapper = styled.div`
    min-width: 400px;
    max-width: 1100px;
    margin: 50px auto 50px auto;
    padding-right: 70px;
    text-align: center;
`;
