const axiomDomainStages = {
    InternalNew: 'BAB40887-6870-E611-8255-0025B52A112B',
    InternalActive: 'C0B40887-6870-E611-8255-0025B52A112B',
    InternalInactive: 'C1B40887-6870-E611-8255-0025B52A112B',
    InternalCanceled: 'C2B40887-6870-E611-8255-0025B52A112B',
    InternalExtinct: 'C3B40887-6870-E611-8255-0025B52A112B',
    InternalMerged: 'C4B40887-6870-E611-8255-0025B52A112B',
    InternalDissolving: 'EF9079CA-1F54-41AA-933E-E2117F3745E7',
    InternalDissolved: '403F4BDA-FAB9-4AA7-8D8F-0FB0DB5FB9C2',
    ExternalPublicNew: '66BE43F8-52E2-E611-8255-0025B52A112B',
    ExternalPublicActive: '67BE43F8-52E2-E611-8255-0025B52A112B',
    ExternalPublicArchived: '68BE43F8-52E2-E611-8255-0025B52A112B',
    ExternalPublicCanceled: '69BE43F8-52E2-E611-8255-0025B52A112B',
    ExternalPublicExtinct: '6ABE43F8-52E2-E611-8255-0025B52A112B',
    ExternalPublicMerged: '6BBE43F8-52E2-E611-8255-0025B52A112B',
    PlaceholderNew: '7C04525F-6C55-E811-B65A-0025B51B113B',
    PlaceholderActive: '7D04525F-6C55-E811-B65A-0025B51B113B',
    PlaceholderMerged: '7E04525F-6C55-E811-B65A-0025B51B113B',
    PlaceholderCanceled: '7F04525F-6C55-E811-B65A-0025B51B113B',
    IndividualNew: '8E92C8BD-FEFE-EC11-8329-0EFDDF802179',
    IndividualActive: '8F92C8BD-FEFE-EC11-8329-0EFDDF802179',
    IndividualInactive: '9092C8BD-FEFE-EC11-8329-0EFDDF802179',
    IndividualCanceled: '9192C8BD-FEFE-EC11-8329-0EFDDF802179',
    IndividualExtinctIndividual: '9292C8BD-FEFE-EC11-8329-0EFDDF802179',
    IndividualMerged: '9392C8BD-FEFE-EC11-8329-0EFDDF802179',
};

export const supportedAxiomDomainStages = [
    axiomDomainStages.InternalNew,
    axiomDomainStages.InternalActive,
    axiomDomainStages.InternalInactive,
    axiomDomainStages.InternalCanceled,
    axiomDomainStages.InternalExtinct,
    axiomDomainStages.InternalMerged,
    axiomDomainStages.InternalDissolving,
    axiomDomainStages.InternalDissolved,
    axiomDomainStages.ExternalPublicNew,
    axiomDomainStages.ExternalPublicActive,
    axiomDomainStages.ExternalPublicArchived,
    axiomDomainStages.ExternalPublicCanceled,
    axiomDomainStages.ExternalPublicExtinct,
    axiomDomainStages.ExternalPublicMerged,
];
