import { Grid, StackPanel } from '@bxgrandcentral/controls';

import PermissionInfoTable from './PermissionInfoTable';
import { SPACING } from 'Utilities/Layout';
import Spinner from 'components/Spinner/Spinner';
import Text from 'components/Text/Text';
import useUserPermissionInfo from './hooks/use-user-permission-info';

type PermissionInfoTableProps = {
    settingsMode?: boolean;
    title?: string;
};

export default function PermissionInfo({
    settingsMode = false,
    title = 'Currently you have the following permissions:',
}: PermissionInfoTableProps) {
    const userPermissionInfo = useUserPermissionInfo();

    if (userPermissionInfo.isLoading) {
        return (
            <StackPanel horizontalAlignment='center'>
                <Spinner />
            </StackPanel>
        );
    }

    if (userPermissionInfo.noPermissions && settingsMode) {
        return (
            <StackPanel padding={`${!settingsMode ? `${SPACING.XXL}px 0 ${SPACING.LG}px` : `0 0 ${SPACING.LG}px`}`}>
                <Text variant='body'>You don't have any permissions.</Text>
            </StackPanel>
        );
    }

    if (userPermissionInfo.noPermissions) {
        return <></>;
    }

    return (
        <>
            <StackPanel padding={`${!settingsMode ? `${SPACING.XXL}px 0 ${SPACING.LG}px` : `0 0 ${SPACING.LG}px`}`}>
                <Text variant='body'>{title}</Text>
            </StackPanel>
            <Grid
                columnDefinitions={`1fr${!settingsMode ? ' 1fr 1fr' : ''}`}
                itemGap={settingsMode ? SPACING.LG : SPACING.XXL}
                width={settingsMode ? '100%' : 800}
                margin={'0 auto'}>
                <PermissionInfoTable roles={userPermissionInfo.legalEntityPermissions} title='Legal Entity' />
                <PermissionInfoTable roles={userPermissionInfo.businessUnitPermissions} title='Business Unit' />
                <PermissionInfoTable roles={userPermissionInfo.subBusinessUnitPermissions} title='Sub-Business Unit' />
                <PermissionInfoTable roles={userPermissionInfo.lawFirmPermissions} title='Law Firm' />
                <PermissionInfoTable roles={userPermissionInfo.registeredAgentPermissions} title='Registered Agent' />
                <PermissionInfoTable roles={userPermissionInfo.externalAdminPermissions} title='External Admin' />
                <PermissionInfoTable roles={userPermissionInfo.otherPermissions} title='Other Permissions' />
            </Grid>
        </>
    );
}
