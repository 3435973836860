import { supportedAxiomDomainStages } from 'models';
import { useCreationViewContext } from '../context/creation-view-context';
import { useMemo } from 'react';

export default function useIsSupportedEntity() {
    const {
        state: {
            sections: {
                LegalEntityInformation: { domainStageID },
            },
        },
    } = useCreationViewContext();

    const isSupportedEntity = useMemo(() => {
        if (!domainStageID) {
            return true;
        }

        return supportedAxiomDomainStages.includes(domainStageID.toUpperCase());
    }, [domainStageID]);

    return isSupportedEntity;
}
