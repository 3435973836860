import { DateTimeField, DeleteButton, Select, StackPanel, TextField } from 'components';
import { DoubleColumnLayout, HORIZONTAL_FORM_PADDING, SPACING } from 'Utilities/Layout';
import { isCaymanIslands, isLuxembourg, isUnitedStates } from 'models';
import { isEmpty, isEqual, isNil } from 'lodash';

import { GlobalState } from 'GlobalState';
import { RegistrationData as RegistrationDataModel } from '../../model';
import { TextBlock } from '@bxgrandcentral/controls';
import { ValidationErrors } from 'modules/LegalEntityCreation/context/model';
import { convertToLocalDate } from 'Utilities/date';
import { getRegisteredAgents } from '../../../LegalEntityDocumentPreparation/utils';
import { removeAt } from 'Utilities/array';
import { useLegalEntityInformation } from 'modules/LegalEntityCreation/context/Provider';
import { useMemo } from 'react';
import { useReferenceData } from 'api';

type Props = {
    index: number;
    validation: ValidationErrors<RegistrationDataModel>;
    noPendingValues?: RegistrationDataModel;
};

export default function DomesticRegistration({ index, validation = {}, noPendingValues }: Props) {
    const {
        data: { RegisteredAgent, Country, State },
    } = useReferenceData();

    const {
        state: {
            data: {
                values: { RegistrationData = [] },
            },
            isEditable,
        },
        setValue,
    } = useLegalEntityInformation();

    const {
        taxRegistrationId,
        domesticCountry,
        domesticRegisteredAgent,
        domesticState,
        formationDate,
        dissolutionDate,
        registrationNumber,
        isDeleted,
    } = RegistrationData[index];

    const isPendingChange = (newValue?: any, noPendingValue?: any) => {
        return !isEditable && !isNil(noPendingValues) ? !isEqual(newValue, noPendingValue) : undefined;
    };

    const countryHasRegisteredAgent = useMemo(() => getRegisteredAgents(domesticCountry), [domesticCountry]);

    const filteredStates = useMemo(
        () => State?.filter(({ ParentId }) => ParentId === `${domesticCountry}`),
        [State, domesticCountry]
    );

    const onCountryChanged = (newValue: number) => {
        const updated = [...RegistrationData];
        updated[index].domesticCountry = newValue;
        updated[index].domesticState = undefined;
        updated[index].domesticRegisteredAgent = getRegisteredAgents(newValue)
            ? updated[index].domesticRegisteredAgent
            : undefined;

        setValue('RegistrationData', updated);
    };

    async function setIsDeleted() {
        if (RegistrationData[index]?.taxRegistrationId) {
            RegistrationData[index].isDeleted = !isDeleted;
            setValue('RegistrationData', Array.of(...RegistrationData));
        } else {
            await GlobalState.openDialog({
                variant: 'info',
                title: 'Delete Domestic Registration Data',
                content: 'Are you sure you want to remove this Domestic Registration?',
            }).then((response) => {
                if (response) {
                    if (RegistrationData[index]?.taxRegistrationId) {
                        RegistrationData[index].isDeleted = !isDeleted;
                        setValue('RegistrationData', Array.of(...RegistrationData));
                    } else {
                        setValue('RegistrationData', removeAt(RegistrationData, index));
                    }
                }
            });
        }
    }

    const isNewItem = !taxRegistrationId || (!isEditable && !isDeleted && taxRegistrationId < 0);

    const isRegistrationEditable = isEditable && !isDeleted;

    const isDeleteButtonVisible = isEditable && (!taxRegistrationId || taxRegistrationId < 0);

    const isStateRequired = useMemo(
        () => isUnitedStates(domesticCountry) || isCaymanIslands(domesticCountry),
        [domesticCountry]
    );

    return (
        <StackPanel key={taxRegistrationId} itemGap={SPACING.SM} padding={HORIZONTAL_FORM_PADDING}>
            {isNewItem && (
                <TextBlock styleName='heading4Style' margin={`0 0 ${SPACING.SM}px 0 `}>
                    New Domestic Registration
                </TextBlock>
            )}
            <Select
                label='Registered Agent'
                labelToolTip={{ component: 'Select the registered agent' }}
                itemsSource={RegisteredAgent}
                isEditable={isRegistrationEditable && countryHasRegisteredAgent}
                isRequired={countryHasRegisteredAgent}
                value={domesticRegisteredAgent}
                isPendingChange={isPendingChange(domesticRegisteredAgent, noPendingValues?.domesticRegisteredAgent)}
                onValueChanged={(newValue) => {
                    const updated = [...RegistrationData];
                    updated[index].domesticRegisteredAgent = newValue;

                    setValue('RegistrationData', updated);
                }}
                validationError={validation.domesticRegisteredAgent}
                styleOverrides={{ opacity: isDeleted ? 0.5 : 1 }}
            />
            <DoubleColumnLayout isDisabled={isDeleted}>
                <StackPanel itemGap={SPACING.SM}>
                    <Select
                        label='Country'
                        labelToolTip={{ component: 'Country in which the legal entity is domiciled' }}
                        itemsSource={Country}
                        isRequired
                        isEditable={isRegistrationEditable}
                        value={domesticCountry}
                        isPendingChange={isPendingChange(domesticCountry, noPendingValues?.domesticCountry)}
                        onValueChanged={(newValue) => {
                            onCountryChanged(newValue);
                        }}
                        validationError={validation.domesticCountry}
                    />
                    <TextField
                        label='Registration Number'
                        labelToolTip={'Select the registered agent'}
                        value={registrationNumber}
                        isEditable={isRegistrationEditable}
                        isPendingChange={isPendingChange(registrationNumber, noPendingValues?.registrationNumber)}
                        onValueChanged={(newValue) => {
                            if (newValue || RegistrationData[index].registrationNumber) {
                                const updated = [...RegistrationData];
                                updated[index].registrationNumber = newValue;
                                setValue('RegistrationData', updated);
                            }
                        }}
                        isRequired={!isLuxembourg(domesticCountry)}
                        validationError={validation.registrationNumber}
                    />
                    <DateTimeField
                        label='Dissolution Date'
                        value={dissolutionDate}
                        isPendingChange={isPendingChange(
                            dissolutionDate?.toString(),
                            noPendingValues?.dissolutionDate?.toString()
                        )}
                        onValueChanged={(newValue) => {
                            if (newValue || RegistrationData[index].dissolutionDate) {
                                const updated = [...RegistrationData];
                                updated[index].dissolutionDate = convertToLocalDate(newValue);
                                setValue('RegistrationData', updated);
                            }
                        }}
                        isEditable={isRegistrationEditable}
                        validationError={validation.dissolutionDate}
                        height={90}
                    />
                </StackPanel>
                <StackPanel itemGap={SPACING.SM}>
                    <Select
                        label='State'
                        labelToolTip={{ component: 'State in which the legal entity is domiciled' }}
                        itemsSource={filteredStates}
                        isRequired={isStateRequired}
                        isEditable={isRegistrationEditable && !isEmpty(filteredStates)}
                        value={domesticState}
                        isPendingChange={isPendingChange(domesticState, noPendingValues?.domesticState)}
                        onValueChanged={(newValue) => {
                            if (newValue || RegistrationData[index].domesticState) {
                                const updated = [...RegistrationData];
                                updated[index].domesticState = newValue;
                                setValue('RegistrationData', updated);
                            }
                        }}
                        validationError={validation.domesticState}
                    />
                    <DateTimeField
                        label='Formation Date'
                        labelToolTip={'Date in which the entity is legally formed'}
                        value={formationDate}
                        isPendingChange={isPendingChange(
                            formationDate?.toString(),
                            noPendingValues?.formationDate?.toString()
                        )}
                        onValueChanged={(newValue) => {
                            if (newValue || RegistrationData[index].formationDate) {
                                const updated = [...RegistrationData];
                                updated[index].formationDate = convertToLocalDate(newValue);
                                setValue('RegistrationData', updated);
                            }
                        }}
                        isEditable={isRegistrationEditable}
                        isRequired={!isLuxembourg(domesticCountry)}
                        validationError={validation.formationDate}
                    />
                </StackPanel>
            </DoubleColumnLayout>
            {isDeleteButtonVisible && (
                <DeleteButton
                    isDeleted={isDeleted}
                    onClick={setIsDeleted}
                    isEnabled={!taxRegistrationId || RegistrationData.length <= 2}
                />
            )}
        </StackPanel>
    );
}
