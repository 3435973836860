import { ColDef, NumberFilter, TextFilter } from 'ag-grid-community';
import { useCallback, useMemo } from 'react';

import { GridApiRef } from 'hooks/use-column-configuration';
import GridDeleteButton from './GridDeleteButton';
import GridPendingColumnRenderer from '../common/GridPendingColumnRenderer';
import { OwnershipDetailsModel } from '../../model';
import { OwnershipDetailsProcessedForGrid } from './use-processed-data-for-grid';
import SetFilter from 'modules/RequestsDashboard/components/SetFilter/SetFilter';
import ParentEntityOIdCellRenderer from 'modules/RequestsDashboard/components/Grid/components/ParentEntityOIdCellRenderer';

const fieldsWithSetFilter = ['interestType', 'currency', 'shareClass', 'votingRight'];

type Props = {
    clearRowSelection: () => void;
    mode: string;
    isStandaloneWorkflow: boolean;
    data?: OwnershipDetailsProcessedForGrid[];
    gridApi?: GridApiRef;
};

export default function useOwnershipDetailsColumnDefs({
    clearRowSelection,
    mode,
    isStandaloneWorkflow,
    data,
    gridApi,
}: Props) {
    const generateSetFilter = useCallback(
        (field?: keyof OwnershipDetailsProcessedForGrid, data?: OwnershipDetailsProcessedForGrid[]) => {
            if (!field || !data) {
                return { filter: false };
            }

            const values = Array.from(new Set(data.map((e) => e[field]))).sort();
            return values.length > 0
                ? {
                      filter: SetFilter,
                      filterParams: {
                          values,
                          maxHeight: '200px',
                      },
                  }
                : {};
        },
        []
    );

    const customComparator = (valueA = '', valueB = '') => {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    const ownershipDetailsColumnDefs: ColDef[] = [
        {
            field: 'hasPendingChange',
            headerName: 'Pending',
            cellClass: 'cell-pending',
            headerTooltip: 'Pending',
            hide: !(mode === 'Approval' && isStandaloneWorkflow),
            maxWidth: 90,
            cellRenderer: GridPendingColumnRenderer,
        },
        {
            field: 'relatedEntityName',
            headerName: 'Parent Entity',
            cellClass: 'cell-relatedEntityName',
            headerTooltip: 'Parent Entity',
            sortable: true,
            comparator: customComparator,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
            cellRenderer: ParentEntityOIdCellRenderer,
            cellRendererParams: {
                redirectTo: 'LegalEntityInformation',
            },
            tooltipValueGetter: (params) => params.data.relatedEntityName,
            minWidth: 250,
            resizable: true,
        },
        {
            field: 'interestType',
            headerName: 'Interest Type',
            cellClass: 'cell-interestType',
            headerTooltip: 'Interest Type',
            sortable: true,
        },
        {
            field: 'ownerShip',
            headerName: 'Ownership %',
            cellClass: 'cell-ownerShip',
            headerTooltip: 'Ownership %',
            sortable: true,
            filter: NumberFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'currency',
            headerName: 'Currency',
            cellClass: 'cell-Currency',
            headerTooltip: 'Currency',
            sortable: true,
        },
        {
            field: 'shareClass',
            headerName: 'Share Class Type',
            cellClass: 'cell-shareClass',
            minWidth: 160,
            headerTooltip: 'Share Class Type',
            sortable: true,
        },
        {
            field: 'nominalValue',
            headerName: 'Nominal Value',
            cellClass: 'cell-nominalValue',
            minWidth: 160,
            headerTooltip: 'Nominal Value',
            sortable: true,
            filter: TextFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'numberOfUnits',
            headerName: 'Number of Units',
            cellClass: 'cell-numberOfUnits',
            minWidth: 160,
            headerTooltip: 'Number of Units',
            sortable: true,
            filter: NumberFilter,
            filterParams: {
                suppressAndOrCondition: true,
            },
        },
        {
            field: 'votingRight',
            headerName: 'Voting Rights',
            cellClass: 'cell-votingRight',
            headerTooltip: 'Voting Rights',
            sortable: true,
        },
        {
            field: 'isDeleted',
            headerName: 'Delete',
            cellClass: 'cell-delete',
            pinned: 'right',
            maxWidth: 85,
            cellRenderer: GridDeleteButton,
            cellRendererParams: {
                clearRowSelection,
            },
            hide: !['Create', 'Edit'].includes(mode),
        },
    ];

    const columnDefs: ColDef<OwnershipDetailsModel>[] = useMemo(() => {
        const newColDefs = ownershipDetailsColumnDefs.map((column: ColDef<OwnershipDetailsModel>) => {
            const { field = '' } = column;
            return {
                ...column,
                ...(fieldsWithSetFilter.includes(field) && {
                    ...generateSetFilter(field as keyof OwnershipDetailsModel, data),
                }),
            };
        });

        return newColDefs;
    }, [ownershipDetailsColumnDefs, data, generateSetFilter, gridApi]);

    return { columnDefs };
}
