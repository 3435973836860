import { Debugger, Expander, FileUpload, Footer, Form, Text } from 'components';
import React, { useMemo } from 'react';
import {
    additionalInfoDocTypes,
    getDocumentTypes,
    getDocumentTypesForTreeView,
} from '../LegalEntityDocumentPreparation/utils';

import ActionPanel from './components/ActionPanel/ActionPanel';
import AlternateNames from './components/AlternateNames/AlternateNames';
import { ContentBox } from 'modules/RequestsDashboard/components/components.styled';
import DocumentTreeList from 'components/controls/DocumentTreeList/DocumentTreeList';
import DomesticRegistrations from './components/DomesticRegistration/DomesticRegistrations';
import InformationAddresses from './components/Addresses/InformationAddresses';
import InformationDetails from './components/InformationDetails/InformationDetails';
import { LegalEntityInformationProps } from './model';
import PageUpdateInfo from '../LegalEntityDissolution/components/PageUpdateInfo';
import RejectReason from '../Shared/RejectReason';
import { RequestStepsView } from '../LegalEntityCreationView/RequestStepsView';
import { SECTION_NAMES } from '../models';
import { SPACING } from 'Utilities/Layout';
import { StackPanel } from '@bxgrandcentral/controls';
import TaxCodes from './components/TaxCodes/TaxCodes';
import { isEmpty } from 'lodash';
import { useCreationViewContext } from '../LegalEntityCreationView/context/creation-view-context';
import useLastCompletedTask from '../LegalEntityCreationView/hooks/use-last-completed-task';
import { useLegalEntityInformation } from '../context/Provider';

export default function LegalEntityInformation(props: LegalEntityInformationProps) {
    const { workItem, mode } = props;
    const context = useLegalEntityInformation();
    const {
        state: {
            data: {
                values: { entityOid, legalTypeId },
            },
        },
    } = context;

    const {
        state: { loadingStates },
    } = useCreationViewContext();

    const newDocumentTypes = useMemo(() => {
        const types = [...additionalInfoDocTypes, ...getDocumentTypes(legalTypeId)];
        return types.sort((a, b) => a.Value.localeCompare(b.Value));
    }, [legalTypeId]);
    const treeViewDocumentTypes = getDocumentTypesForTreeView(SECTION_NAMES.INFORMATION);

    const showDocumentsInReadOnlyTreeView = props.mode === 'Readonly' && props.workItem?.workItemStatus !== 'InProcess';

    const lastSubmitTask = useLastCompletedTask({
        workItem: workItem,
        taskType: 'RequestTaskType',
    });

    const lastApproveTask = useLastCompletedTask({
        workItem: workItem,
        taskType: 'UpdateRequestTaskType',
    });

    return (
        <>
            <Debugger inputState={context.state} requestStep={RequestStepsView.Legal_Entity_Information} />
            <Form>
                <InformationDetails />
                <TaxCodes />
                <DomesticRegistrations />
                <InformationAddresses />
                {props.mode !== 'Readonly' && (
                    <Expander
                        header={'New Documents to be Approved'}
                        requestStep={RequestStepsView.Legal_Entity_Information}
                        content={
                            <FileUpload
                                entityId={entityOid}
                                sectionName={SECTION_NAMES.INFORMATION}
                                showStampedDocuments={false}
                                documentTypes={newDocumentTypes}
                                context={context}
                                effectiveDate='required'
                                standaloneCreateMode={props.mode === 'Create'}
                            />
                        }
                    />
                )}
                <Expander
                    header={showDocumentsInReadOnlyTreeView ? 'Existing Documents' : 'All Documents'}
                    requestStep={RequestStepsView.Legal_Entity_Information}
                    content={
                        <StackPanel margin={`0 0 ${SPACING.XL}px 0`}>
                            {props.mode !== 'Readonly' && (
                                <Text horizontalAlignment='center' verticalAlignment='center' textAlignment='center'>
                                    <p>
                                        Documents listed in this section include both the approved and pending to be
                                        approved documents.
                                    </p>
                                </Text>
                            )}
                            {isEmpty(loadingStates) && (
                                <DocumentTreeList
                                    entityId={entityOid}
                                    sectionName={SECTION_NAMES.INFORMATION}
                                    treeViewDocumentTypes={treeViewDocumentTypes}
                                />
                            )}
                        </StackPanel>
                    }
                />
                <AlternateNames />
                <RejectReason mode={mode} taskType='UpdateRequestTaskType' tasks={workItem?.tasks} />
                <ContentBox>
                    <PageUpdateInfo label={'Last Submitted by'} lastCompletedTask={lastSubmitTask} />
                    <PageUpdateInfo label={'Last Approved by'} lastCompletedTask={lastApproveTask} />
                </ContentBox>
            </Form>
            {props.mode !== 'Readonly' && (
                <Footer>
                    <ActionPanel entityOId={entityOid} {...props} />
                </Footer>
            )}
        </>
    );
}
