import { Brn, Capability } from '../models';
import { useEffect, useState } from 'react';

import { IPermissionService } from '../services/interfaces/IPermissionService';
import { ServiceLocator } from '@bxgrandcentral/shell';
import { useQuery } from 'react-query';

type UserInformation = {
    brns: Brn[];
    capabilities: Capability[];
};

export default function useUserInformation() {
    const service = ServiceLocator.container.resolve(IPermissionService);
    const [userInformation, setUserInformation] = useState<UserInformation>({ brns: [], capabilities: [] });

    const fetch = async (): Promise<Capability[]> => {
        return await service.GetPolicyServerCapabilities();
    };

    const queryResult = useQuery<Capability[], Error>('Capability', fetch, {
        staleTime: 5 * 60 * 1000,
    });

    useEffect(() => {
        const userInformationCopy = { ...userInformation };

        if (queryResult.data?.length) {
            userInformationCopy.brns = queryResult.data.map(({ brn }) => brn);
            userInformationCopy.capabilities = queryResult.data;

            setUserInformation(userInformationCopy);
        }
    }, [queryResult.data]);

    return {
        ...queryResult,
        data: userInformation,
    };
}
